import { ROUTE_LOADER_IDS } from "@config/constants.config";
import { Page } from "@components/Layouts/Page/Page.component";
import { CheckInheritanceStatusForm } from "@components/Forms/CheckInheritanceStatusForm.component";

export function CheckInheritanceStatusTemplate() {
  return (
    <Page
      title="Check your inheritance status"
      routeId={ROUTE_LOADER_IDS.ESTATE_SERVICES}
      pageMainProps={{ bg: "#fff" }}
    >
      <CheckInheritanceStatusForm />
    </Page>
  );
}
