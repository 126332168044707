import { VStack } from "@chakra-ui/react";
import { Stepper } from "@shared/components/Stepper/Stepper.component";
import { ROUTE_LOADER_IDS } from "@config/constants.config";
import { notificationSteps } from "@config/notification-steps.config";
import { InheritorForm } from "@components/Forms/InheritorForm.component";
import { Page } from "@components/Layouts/Page/Page.component";
import { useInstitutionData } from "@src/hooks/useInstitutionData.hook";

export function TellUsAboutYourselfTemplate() {
  const institution = useInstitutionData(ROUTE_LOADER_IDS.ESTATE_SERVICES);
  return (
    <Page
      title="Tell us about yourself"
      routeId={ROUTE_LOADER_IDS.ESTATE_SERVICES}
      pageMainProps={{ bg: "#fff" }}
    >
      <VStack gap={10} width="100%" alignItems="flex-start" px={12}>
        <Stepper steps={notificationSteps} />
        <InheritorForm institution={institution} />
      </VStack>
    </Page>
  );
}
