import { ReactNode, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { FiArrowLeft } from "react-icons/fi";
import {
  Container,
  Box,
  AlertIcon,
  Alert,
  Text,
  ContainerProps,
  Flex,
  FlexProps,
} from "@chakra-ui/react";
import { ROUTE_LOADER_IDS } from "@config/constants.config";
import { useInstitutionData } from "@hooks/useInstitutionData.hook";
import { useNavigation } from "@shared/hooks/useNavigation.hook";
import { useIsMobile } from "@shared/hooks/useIsMobile.hook";
import { BlockerModal } from "@shared/components/BlockerModal/BlockerModal.component";
import { Button } from "@shared/components/Button/Button.component";
import { Header } from "../Header/Header.component";
import { Footer } from "../Footer/Footer.component";
import { postMessageResizeView } from "@shared/utils/postMessageResizeView.utils";

interface PageProps extends ContainerProps {
  children: ReactNode;
  routeId: (typeof ROUTE_LOADER_IDS)[keyof typeof ROUTE_LOADER_IDS];
  title?: string;
  showBack?: boolean;
  error?: string;
  url?: string;
  containerProps?: ContainerProps;
  pageMainProps?: FlexProps;
}

export function Page({
  title,
  showBack,
  children,
  routeId,
  containerProps,
  pageMainProps,
  ...rest
}: Readonly<PageProps>) {
  const { name, embedded } = useInstitutionData(routeId);
  const { handleGoBack } = useNavigation();
  const isMobile = useIsMobile();

  const innerContainerStyle = embedded ? {} : { mt: isMobile ? 8 : 12, mx: isMobile ? 2 : "auto" };

  useEffect(() => {
    const element = window.document.querySelector<HTMLElement>("#page-container");

    // This is going to be our partner's website, ex.: https://www.msufcu.org,
    // and this is REALLY important to allowing cross-origin communication with window.postMessage.
    const referrerHost = document.referrer ? new URL(document.referrer).href : "";

    function updateSizes() {
      postMessageResizeView(referrerHost, {
        width: element?.offsetWidth ?? 0,
        height: element?.offsetHeight ?? 0,
      });
    }

    if (element && referrerHost && embedded) {
      new ResizeObserver(updateSizes).observe(element);
    }
  }, [embedded]);

  return (
    <Container id="page-container" maxW="full" {...rest} h="100%" px={0}>
      {title && (
        <Helmet>
          <meta name="theme-color" content="white" />
          <title>{`${name ?? "Estate center services"} | ${title}`}</title>
        </Helmet>
      )}

      {rest.error && (
        <Box my={4}>
          <Alert status="error">
            <AlertIcon />
            <Text>{rest.error}</Text>
          </Alert>
        </Box>
      )}
      {showBack && (
        <Box my={2}>
          <Button
            fontSize={14}
            variant="whiteLabel"
            fontWeight={700}
            onClick={() => handleGoBack()}
            size="md"
            borderRadius={6}
            leftIcon={<FiArrowLeft />}
          >
            Back
          </Button>
        </Box>
      )}
      <BlockerModal />
      <Header routeId={routeId} />
      <Flex
        id="main-flex"
        as="main"
        direction="column"
        flex="1"
        width="100%"
        height="100%"
        alignItems="center"
        {...pageMainProps}
      >
        <Container
          p={0}
          id="page-inner-container"
          flex="1"
          maxW="5xl"
          {...containerProps}
          {...innerContainerStyle}
        >
          {children}
        </Container>
      </Flex>
      <Footer routeId={routeId} />
    </Container>
  );
}
