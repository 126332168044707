import { Box, HStack, Stack, Text } from "@chakra-ui/react";
import { SecondaryButton } from "@src/components/Buttons/SecondaryButton/SecondaryButton";
import { ROUTES, ROUTE_LOADER_IDS } from "@src/config/constants.config";
import { useNavigation } from "@shared/hooks/useNavigation.hook";

export default function InheritanceVaultAlert() {
  const routeId = ROUTE_LOADER_IDS.ESTATE_SERVICES;
  const { navigate } = useNavigation();

  const handleToInheritancePageNavigate = () => {
    return navigate(ROUTES.INHERITANCE_VAULT);
  };

  return (
    <Box borderRadius="md" w={"full"}>
      <Stack direction={{ base: "column", sm: "column" }} spacing={6} justify="space-between">
        <HStack spacing={6} justify="space-between">
          <Stack>
            <Text fontSize="md">
              A special vault was created with important documents and cherished items for you.
            </Text>
          </Stack>

          <SecondaryButton
            routeId={routeId}
            fontSize="16px"
            fontWeight={600}
            onClick={handleToInheritancePageNavigate}
          >
            View vault{" "}
          </SecondaryButton>
        </HStack>
      </Stack>
    </Box>
  );
}
