import { Link } from "react-router-dom";
import { Container, HStack, Box, Image, Heading } from "@chakra-ui/react";
import { FiSmartphone } from "react-icons/fi";
import { ROUTES, ROUTE_LOADER_IDS } from "@config/constants.config";
import { useInstitutionData } from "@hooks/useInstitutionData.hook";
import { Button } from "@shared/components/Button/Button.component";

interface HeaderProps {
  routeId: (typeof ROUTE_LOADER_IDS)[keyof typeof ROUTE_LOADER_IDS];
}

export function Header({ routeId }: HeaderProps) {
  const institution = useInstitutionData(routeId);

  if (institution.embedded) return null;

  return (
    <Box as="section" w="full" boxShadow="sm" borderBottom="1px solid #2d374814">
      <Box boxShadow="sm" bg="white">
        <Container py={6} maxW="6xl">
          <HStack justify="space-between">
            <Link to={ROUTES.ESTATE_SERVICES} title={institution.name}>
              <Image
                src={institution.config?.logo}
                alt={institution.name}
                width={{ base: 150, md: 200 }}
              />
            </Link>
            <Heading variant="h1" size={{ base: "sm", md: "lg" }}>
              Notify {institution.name}
            </Heading>
            <Button
              rightIcon={<FiSmartphone />}
              colorScheme="blue"
              variant="whiteLabelGhost"
              border="1px solid"
              borderColor="whiteLabel.primary"
              borderRadius="md"
              size={{ base: "md", md: "lg" }}
            >
              Call Us
            </Button>
          </HStack>
        </Container>
      </Box>
    </Box>
  );
}
