import { ROUTES } from "@config/constants.config";
import { UploadDocumentTemplate } from "@templates/UploadDocument/UploadDocument.template";

export default function UploadWillTestPage() {
  return (
    <UploadDocumentTemplate
      documentType="wills"
      uploadFormTitle="Upload additional documents"
      uploadFormLabel="Upload additional documents"
      uploadFormImagePath="https://static.trustribbon.com/assets/animations/cert-of-trust-icon.png"
      isRequired={false}
      navigateToOnSubmit={ROUTES.REVIEW_INFO}
    />
  );
}
