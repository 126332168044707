import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Flex, HStack, Heading, Divider } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ROUTES } from "@config/constants.config";
import { useNotifyInfoStore } from "@store/notifyInfo.store";
import { dateOfBirthMask } from "@shared/config/masks.config";
import { useNavigation } from "@shared/hooks/useNavigation.hook";
import { FormInput } from "@shared/components/FormComponents/FormInput.component";
import { useIsMobile } from "@shared/hooks/useIsMobile.hook";
import { DeceasedPersonStore, deceasedPersonFormSchema } from "@shared/config/schema.validator";
import { PIIInputs } from "@shared/components/FormComponents/PIIInputs.component";
import { NotifyNavigationBlock } from "./Blocks/NotifyNavigation.block.component";

const defaultValues = {
  firstName: "",
  middleName: null,
  lastName: "",
  dateOfBirth: "",
  ssn: "",
};

const formInputProps = {
  size: "lg",
  labelProps: { backgroundColor: "#fff" },
  formControlProps: {
    width: "auto",
    flexGrow: 1,
  },
};

export function DeceasedPersonForm() {
  const isMobile = useIsMobile();
  const { deceasedInfo, setDeceasedInfo } = useNotifyInfoStore(state => ({
    deceasedInfo: state.deceasedInfo,
    setDeceasedInfo: state.setDeceasedInfo,
  }));
  const { navigate } = useNavigation();
  const formMethods = useForm<DeceasedPersonStore>({
    defaultValues: deceasedInfo ?? defaultValues,
    resolver: yupResolver(deceasedPersonFormSchema),
  });

  const { handleSubmit, formState } = formMethods;
  const { errors } = formState;
  const hasErrors = !!errors.firstName || !!errors.lastName || !!errors.ssn;

  const onSubmit: SubmitHandler<DeceasedPersonStore> = data => {
    setDeceasedInfo(data);

    return navigate(ROUTES.INHERITOR_INFO);
  };

  return (
    <FormProvider {...formMethods}>
      <Flex
        as="form"
        id="passed-one-form"
        direction="column"
        onSubmit={handleSubmit(onSubmit)}
        width="100%"
      >
        <Flex gap={6} direction="column" mb={16}>
          <Heading color="#171923" variant="h1" fontSize={24} fontWeight={600} lineHeight="32px">
            Please tell us about the person who passed away
          </Heading>
          <PIIInputs formInputProps={formInputProps} />
          <HStack
            pr={3}
            align="flex-start"
            direction="row"
            width={isMobile ? "100%" : "50%"}
            flexWrap="wrap"
          >
            <FormInput
              data-testid="date-of-birth-input"
              label="Date of Birth"
              name="dateOfBirth"
              variant="whiteLabel"
              mask={dateOfBirthMask}
              maxLength={12}
              registerOptions={{ required: true }}
              size="lg"
              labelProps={{ backgroundColor: "#fff" }}
            />
          </HStack>
        </Flex>
        <Flex gap={6} direction="column" mb={16}>
          <Divider color="#CBD5E0" />
          <NotifyNavigationBlock isDisabled={hasErrors} />
        </Flex>
      </Flex>
    </FormProvider>
  );
}
