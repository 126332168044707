export const AccountTransferStatus = {
  transfer_completed: "Transfer Completed",
  submitted: "Submitted - Not started",
  needs_info: "Needs more information",
  under_review: "Under Review",
  confirmed: "Confirmed",
};

export type InstitutionConfig = {
  institution: string;
  subdomain: string;
  theme: {
    logo: string;
    primary: string;
    secondary: string | null;
  };
};
