import { captureException } from "@sentry/react";
import { GetInstitutionBySubdomainResponse } from "@trustribbon/ribbon-ec-types";
import { CONSTANTS } from "@config/constants.config";
import { get } from "./base.service";

export const fetchInstitutionConfig = async (
  subdomain: string
): Promise<GetInstitutionBySubdomainResponse> => {
  try {
    const data = await get<GetInstitutionBySubdomainResponse>(
      `${CONSTANTS.VITE_APP_API_URL}/institutions/${subdomain}`
    );

    return data;
  } catch (error) {
    captureException(error);
    throw error;
  }
};
