import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Spacer,
  Stack,
  Text,
  SimpleGrid,
  VStack,
} from "@chakra-ui/react";
import { useIsMobile } from "@shared/hooks/useIsMobile.hook";
import { FormProvider, SubmitHandler, useFieldArray, useForm, useWatch } from "react-hook-form";
import PromoBox from "@templates/CheckSubmittedInfo/components/PromoBox.checkSubmittedInfo";
import { SearchSubmittedInheritanceCaseResponse } from "@trustribbon/ribbon-ec-types";
import { FaArrowRight } from "react-icons/fa6";
import { Fragment, useState } from "react";
import { FinancialAccountAllocationWithAccountNumberAndType } from "@trustribbon/ribbon-ec-types";
import { formatNumberToCurrency } from "@shared/utils/string.utils";
import { ConfirmationModal, useConfirmationModal } from "@shared/hooks/useConfirmationModal.hook";
import WithdrawalRequestSection from "@src/templates/CheckSubmittedInfo/components/WithdrawalRequestSection.checkSubmitted";
import { postAccountAllocation } from "@src/services/accountWithdrawal.service";
import { useAlert } from "@shared/hooks/useAlert.hook";
import { useInstitutionData } from "@hooks/useInstitutionData.hook";
import { ROUTE_LOADER_IDS } from "@src/config/constants.config";

type WithdrawalRequestProps = {
  checkStatusResponse: SearchSubmittedInheritanceCaseResponse;
};

const buttonProps = {
  size: "lg",
  border: "1px solid",
  borderColor: "whiteLabel.primary",
  padding: "16px 32px",
  fontSize: { base: "sm", md: "18px" },
  fontWeight: 700,
  lineHeight: "24px",
  w: "50%",
  variant: "whiteLabel",
  _hover: {
    color: "white",
    bg: "whiteLabel.secondary",
    border: "1.9px solid",
    borderColor: "whiteLabel.secondary",
  },
};

export default function WithdrawalRequest({ checkStatusResponse }: WithdrawalRequestProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [modalOptions, openModal, closeModal] = useConfirmationModal();
  const { setShowAlert, AlertComponent } = useAlert(10000, false);
  const institution = useInstitutionData(ROUTE_LOADER_IDS.ESTATE_SERVICES);

  const isLiabilityCaseWithdrawal = ["2221"].includes(String(checkStatusResponse?.id));

  const defaultLiabilityAllocations = [
    {
      financialAccountId: "some-id-1",
      accountNumber: "1392223323244",
      accountType: "Auto Loan",
      amount: "10100",
      inheritorId: checkStatusResponse?.inheritor.id,
      deceasedPersonId: checkStatusResponse?.deceasedPerson.id,
      isLiability: true,
      institutionId: checkStatusResponse?.institutionId,
      distributionMethod: "none",
    },
  ];

  const defaultValues: {
    finAccountAllocations: (FinancialAccountAllocationWithAccountNumberAndType & {
      isLiability?: boolean;
    })[];
  } = {
    finAccountAllocations: [],
  };

  const formMethods = useForm<{
    finAccountAllocations: FinancialAccountAllocationWithAccountNumberAndType[];
  }>({
    defaultValues: isLiabilityCaseWithdrawal
      ? {
          finAccountAllocations: defaultLiabilityAllocations,
        }
      : {
          finAccountAllocations:
            checkStatusResponse?.allocations?.financialAccountAllocations?.map(a => ({
              ...a,
              distributionMethod:
                a.distributionMethod === "pending" ? "open_account" : a.distributionMethod,
            })) || [],
        } ?? defaultValues,
    // resolver: yupResolver(yup.object().shape()), //TODO will need to revisit this
  });
  const { fields } = useFieldArray({
    control: formMethods.control,
    name: "finAccountAllocations",
  });

  const isMobile = useIsMobile();
  const { handleSubmit, control } = formMethods;

  const finAccountAllocationsValue = useWatch({
    control,
    name: "finAccountAllocations",
  });

  const onSubmit: SubmitHandler<{
    finAccountAllocations?: FinancialAccountAllocationWithAccountNumberAndType[];
  }> = async data => {
    const { finAccountAllocations: allocations } = data;

    if (!allocations) return undefined;
    if (
      allocations?.find(
        fa =>
          fa?.distributionMethod &&
          ["requested_check", "transfer_money"].includes(fa.distributionMethod)
      )
    ) {
      openModal(async () => {
        setIsLoading(true);
        await postAccountAllocation(`${checkStatusResponse?.id}/request-withdrawal`, {
          allocations: allocations.map(
            (allocation: FinancialAccountAllocationWithAccountNumberAndType) => {
              return {
                financialAccountId: allocation.financialAccountId,
                accountNumber: allocation.accountNumber,
                accountType: allocation.accountType,
                amount: Number(allocation.accountNumber),
                inheritorId: allocation.inheritorId,
                deceasedPersonId: allocation.deceasedPersonId,
                institutionId: allocation.institutionId,
                distributionMethod: allocation.distributionMethod,
              };
            }
          ),
        }).then(() => {
          closeModal();
          setIsLoading(false);
        });
      });
    } else {
      try {
        setIsLoading(true);
        await postAccountAllocation(`${checkStatusResponse?.id}/request-withdrawal`, {
          allocations: allocations.map(
            (allocation: FinancialAccountAllocationWithAccountNumberAndType) => {
              return {
                financialAccountId: allocation.financialAccountId,
                accountNumber: allocation.accountNumber,
                accountType: allocation.accountType,
                amount: Number(allocation.accountNumber),
                inheritorId: allocation.inheritorId,
                deceasedPersonId: allocation.deceasedPersonId,
                institutionId: allocation.institutionId,
                distributionMethod: allocation.distributionMethod,
              };
            }
          ),
        }).then(() => {
          setIsLoading(false);
          if (institution.config?.accountOpenUrl) {
            document.location = institution.config?.accountOpenUrl;
          }
        });
      } catch (error) {
        console.log(error);
        setShowAlert({
          title: "An error occurred while submitting your information.",
          description: "Please try again later.",
          status: "error",
        });
      }
    }
    return null;
  };

  return (
    <Stack>
      <Flex direction="column" pb={8} px={isMobile ? 2 : 12}>
        {isLiabilityCaseWithdrawal ? (
          <>
            <Heading fontSize={24} fontWeight={600} color="#171923">
              Your special offer
            </Heading>
            <Divider color="#CBD5E0" />
          </>
        ) : (
          <>
            <Heading fontSize={24} fontWeight={600} color="#171923">
              Distribute funds
            </Heading>
            <Divider color="#CBD5E0" />
          </>
        )}

        <AlertComponent />
        {isLiabilityCaseWithdrawal ||
        finAccountAllocationsValue.find(
          a => a.distributionMethod === "open_account" || a.distributionMethod === "pending"
        ) ? (
          <Box my={4}>
            <PromoBox />
          </Box>
        ) : (
          <Alert status={"warning"} gap={3} variant="top-accent" my={8}>
            <AlertIcon />
            <VStack alignItems="start" justifyContent="center" gap={0} width="full">
              <AlertTitle mr={2}>Withdrawal Eligibility Notice</AlertTitle>
              <AlertDescription>
                The withdrawal method you have selected does not qualify for a promotional offer.
              </AlertDescription>
            </VStack>
          </Alert>
        )}
        <FormProvider {...formMethods}>
          <ConfirmationModal
            {...modalOptions}
            isLoading={isLoading}
            modalProps={{
              closeOnEsc: false,
              closeOnOverlayClick: false,
            }}
            modalHeader={
              <Text fontSize={18} color="#2D3748" fontWeight={700} lineHeight="28px">
                Confirm withdrawal request
              </Text>
            }
            modalBody={
              <VStack alignItems="flex-start">
                <Text
                  fontSize={{ base: "md", sm: "md" }}
                  color="#2D3748"
                  fontWeight={400}
                  lineHeight="20px"
                  pb={5}
                >
                  Please confirm that you will not be eligible for a promotion and this process may
                  have a different timeline to receive the funds as you requested.{" "}
                  <Text as={"span"} fontWeight={600}>
                    Additional documentation may be required.{" "}
                  </Text>
                </Text>
              </VStack>
            }
            modalConfirmButton="Confirm"
          />

          {isLiabilityCaseWithdrawal ? (
            <>
              <Heading fontSize={24} fontWeight={600} color="#171923">
                Loans
              </Heading>
              <Divider color="#CBD5E0" mb={2} />
            </>
          ) : null}
          <form onSubmit={handleSubmit(onSubmit)} id="distribution-method-form">
            <Stack spacing={12}>
              <Stack spacing={4}>
                {fields.map((field, index) => {
                  const id = `finAccountAllocations.${index}.allocationAccountId`;

                  return (
                    <Fragment key={id}>
                      <WithdrawalRequestSection
                        rowId={index}
                        accountAllocation={field}
                        numberOfAccountAllocation={fields.length}
                      />
                    </Fragment>
                  );
                })}
              </Stack>
              <HStack spacing={2} justifyContent={"space-between"}>
                {isLiabilityCaseWithdrawal ? (
                  <Stack spacing={0}>
                    <Text fontWeight={600} fontSize={"xs"} color={"gray.500"}>
                      TOTAL LiABILITIES
                    </Text>
                    <HStack spacing={0}>
                      <VStack align="start" spacing={4}>
                        <Text
                          fontWeight={600}
                          fontSize={"2xl"}
                          px={0}
                          mx={0}
                          sx={{ m: "-6px", mr: 1 }}
                        >
                          {"$10,100"}
                        </Text>
                        <HStack spacing={0}>
                          <Box mt={8} borderRadius="md">
                            <Text fontWeight={600} fontSize="xl" mb={4} color="gray.700">
                              Contact Us
                            </Text>
                            <SimpleGrid columns={[1, 2, 4]} spacing={6}>
                              <Box>
                                <Text fontWeight={600} color="gray.600" mb={2}>
                                  Member Service
                                </Text>
                                <Text color="gray.500">703-526-0200</Text>
                              </Box>
                              <Box>
                                <Text fontWeight={600} color="gray.600" mb={2}>
                                  Branch Hours
                                </Text>
                                <Text color="gray.500">Monday – Friday:</Text>
                                <Text color="gray.500">9:00am - 5:00pm</Text>
                                <Text color="gray.500">Saturday:</Text>
                                <Text color="gray.500">9:00am - 1:00pm</Text>
                              </Box>
                              <Box>
                                <Text fontWeight={600} color="gray.600" mb={2}>
                                  Drive-up Hours
                                </Text>
                                <Text color="gray.500">Monday – Friday:</Text>
                                <Text color="gray.500">9:00am - 5:00pm</Text>
                                <Text color="gray.500">Saturday:</Text>
                                <Text color="gray.500">9:00am - 1:00pm</Text>
                              </Box>
                              <Box>
                                <Text fontWeight={600} color="gray.600" mb={2}>
                                  Contact Center Hours
                                </Text>
                                <Text color="gray.500">Monday - Friday:</Text>
                                <Text color="gray.500">9:00am - 5:00pm</Text>
                                <Text color="gray.500">Saturday:</Text>
                                <Text color="gray.500">9:00am - 1:00pm</Text>
                              </Box>
                            </SimpleGrid>
                          </Box>
                        </HStack>
                      </VStack>
                    </HStack>
                  </Stack>
                ) : (
                  <Stack spacing={0}>
                    <Text fontWeight={600} fontSize={"xs"} color={"gray.500"}>
                      TOTAL INHERITANCE
                    </Text>
                    <HStack spacing={0} pl={1}>
                      <Text
                        fontWeight={600}
                        fontSize={"2xl"}
                        px={0}
                        mx={0}
                        sx={{ m: "-6px", mr: 1 }}
                      >
                        {`${checkStatusResponse?.allocations?.totalInheritanceAmount ? formatNumberToCurrency(checkStatusResponse?.allocations?.totalInheritanceAmount) : "Not specified"}`}{" "}
                      </Text>
                      {finAccountAllocationsValue.find(
                        a =>
                          a.distributionMethod === "open_account" ||
                          a.distributionMethod === "pending"
                      ) ? (
                        <Text
                          fontWeight={800}
                          fontSize={{ base: "xs", md: "sm" }}
                          color={"green.500"}
                        >
                          +$300 PROMO OFFER
                        </Text>
                      ) : (
                        <Text
                          fontWeight={800}
                          fontSize={{ base: "xs", md: "md" }}
                          color={"red.500"}
                          as="s"
                        >
                          +$300 PROMO OFFER
                        </Text>
                      )}
                    </HStack>
                  </Stack>
                )}

                <Spacer />
                {!isLiabilityCaseWithdrawal && (
                  <Button
                    width={"full"}
                    {...buttonProps}
                    type={"submit"}
                    gap={4}
                    flexGrow={1}
                    isLoading={isLoading}
                    rightIcon={<FaArrowRight size={16} />}
                  >
                    {"Request withdrawal"}
                  </Button>
                )}
              </HStack>
            </Stack>
          </form>
        </FormProvider>
      </Flex>
    </Stack>
  );
}
