import { useEffect, useRef } from "react";
import { Badge, Divider, Flex, HStack, Heading, Text, VStack } from "@chakra-ui/react";
import { HiCheckCircle } from "react-icons/hi";
import { ROUTE_LOADER_IDS } from "@config/constants.config";
import { useNotifyInfoStore } from "@store/notifyInfo.store";
import { useIsMobile } from "@shared/hooks/useIsMobile.hook";
import { useClearAllStores } from "@hooks/useClearStores.hook";
import { Page } from "@components/Layouts/Page/Page.component";
import { AccountTransferStatus } from "@src/types";
import { formatPhoneNumber } from "@shared/utils/string.utils";
import { MdLock } from "react-icons/md";

export function SubmittedInfoTemplate() {
  const isMobile = useIsMobile();
  const { inheritorInfo, deceasedInfo, notifyResponse } = useNotifyInfoStore();
  const { clearAllStores } = useClearAllStores();
  const valuesRef = useRef({ inheritorInfo, deceasedInfo, notifyResponse });

  const {
    inheritorInfo: tempInheritorInfo,
    deceasedInfo: tempDeceasedInfo,
    notifyResponse: tempNotifyResponse,
  } = valuesRef.current;

  const caseID = tempNotifyResponse?.caseId ?? "N/A";

  useEffect(() => {
    clearAllStores();
  }, [clearAllStores]);

  return (
    <Page
      title="Submitted Info"
      routeId={ROUTE_LOADER_IDS.ESTATE_SERVICES}
      pageMainProps={{ bg: "#fff" }}
    >
      <Flex direction="column" gap={6}>
        <Flex direction="column">
          <HStack>
            {!isMobile ? <HiCheckCircle size={34} color="#38A169" /> : null}
            <Heading variant="h1" fontWeight={600} lineHeight="56px" fontSize="26">
              Your request has been successfully submitted
            </Heading>
          </HStack>
          <Divider color="#CBD5E0" />
        </Flex>
        <Flex gap={8} direction="column" pt={4}>
          <HStack gap={6} align="flex-start" direction="row" width="100%" flexWrap="wrap">
            <VStack flexBasis="71%" flexGrow={1} alignItems="flex-start">
              <Text fontSize="20px" fontWeight={600} lineHeight="32px">
                Case ID #{caseID}
              </Text>
            </VStack>
            <HStack gap={3} flexBasis="20%" flexGrow={1} alignItems="center">
              <Text fontSize="20px" fontWeight={600} lineHeight="32px">
                Status:{" "}
              </Text>
              <Badge
                color="#744210"
                bg="#FEEBCB"
                textTransform="none"
                fontSize={"md"}
                borderRadius={"md"}
              >
                {
                  AccountTransferStatus[
                    (tempNotifyResponse?.status as keyof typeof AccountTransferStatus) ??
                      "submitted"
                  ]
                }
              </Badge>
            </HStack>
          </HStack>
          <VStack width="100%" gap={6}>
            <HStack gap={6} align="flex-start" direction="row" width="100%" flexWrap="wrap">
              <VStack flexBasis="20%" flexGrow={1} alignItems="flex-start">
                <Text fontSize="16px" lineHeight="24px" color="#718096" whiteSpace="nowrap">
                  Deceased First Name:
                </Text>
                <Text fontSize="16px" fontWeight={600} lineHeight="24px" color="#171923">
                  {tempDeceasedInfo?.firstName}
                </Text>
              </VStack>
              <VStack flexBasis="20%" flexGrow={1} alignItems="flex-start">
                <Text fontSize="16px" lineHeight="24px" color="#718096" whiteSpace="nowrap">
                  Deceased Middle Name:
                </Text>
                <Text fontSize="16px" fontWeight={600} lineHeight="24px" color="#171923">
                  {tempDeceasedInfo?.middleName}
                </Text>
              </VStack>
              <VStack flexBasis="20%" flexGrow={1} alignItems="flex-start">
                <Text fontSize="16px" lineHeight="24px" color="#718096" whiteSpace="nowrap">
                  Deceased Last Name:
                </Text>
                <Text fontSize="16px" fontWeight={600} lineHeight="24px" color="#171923">
                  {tempDeceasedInfo?.lastName}
                </Text>
              </VStack>
              <VStack flexBasis="20%" flexGrow={1} alignItems="flex-start">
                <Text fontSize="16px" lineHeight="24px" color="#718096" whiteSpace="nowrap">
                  {isMobile ? "SSN" : "Social Security Number"}:
                </Text>
                <Text
                  fontSize="16px"
                  fontWeight={600}
                  lineHeight="24px"
                  color="#171923"
                  whiteSpace="nowrap"
                >
                  {tempNotifyResponse?.deceasedPerson?.ssn}
                </Text>
              </VStack>
            </HStack>
            <HStack gap={6} align="flex-start" direction="row" width="100%" flexWrap="wrap">
              <VStack flexBasis="20%" flexGrow={1} alignItems="flex-start">
                <Text fontSize="16px" lineHeight="24px" color="#718096" whiteSpace="nowrap">
                  Inheritor First Name:
                </Text>
                <Text fontSize="16px" fontWeight={600} lineHeight="24px" color="#171923">
                  {tempInheritorInfo?.firstName}
                </Text>
              </VStack>
              <VStack flexBasis="20%" flexGrow={1} alignItems="flex-start">
                <Text fontSize="16px" lineHeight="24px" color="#718096" whiteSpace="nowrap">
                  Inheritor Middle Name:
                </Text>
                <Text fontSize="16px" fontWeight={600} lineHeight="24px" color="#171923">
                  {tempInheritorInfo?.middleName}
                </Text>
              </VStack>
              <VStack flexBasis="20%" flexGrow={1} alignItems="flex-start">
                <Text fontSize="16px" lineHeight="24px" color="#718096" whiteSpace="nowrap">
                  Inheritor Last Name:
                </Text>
                <Text fontSize="16px" fontWeight={600} lineHeight="24px" color="#171923">
                  {tempInheritorInfo?.lastName}
                </Text>
              </VStack>
              <VStack flexBasis="20%" flexGrow={1} alignItems="flex-start">
                <Text fontSize="16px" lineHeight="24px" color="#718096" whiteSpace="nowrap">
                  {isMobile ? "SSN" : "Social Security Number"}:
                </Text>
                <Text fontSize="16px" fontWeight={600} lineHeight="24px" color="#171923">
                  {tempNotifyResponse?.inheritor?.ssn}
                </Text>
              </VStack>
            </HStack>
            <HStack gap={6} align="flex-start" direction="row" width="100%" flexWrap="wrap">
              <VStack alignItems="flex-start" flexBasis="23%">
                <Text fontSize="16px" lineHeight="24px" color="#718096" whiteSpace="nowrap">
                  Inheritor Email Address:
                </Text>
                <Text
                  fontSize="16px"
                  fontWeight={600}
                  lineHeight="24px"
                  color="#171923"
                  whiteSpace="nowrap"
                >
                  {tempInheritorInfo?.email}
                </Text>
              </VStack>
              <VStack alignItems="flex-start" flexBasis="auto" flexGrow={1}>
                <Text fontSize="16px" lineHeight="24px" color="#718096" whiteSpace="nowrap">
                  Inheritor Phone Number:
                </Text>
                <Text fontSize="16px" fontWeight={600} lineHeight="24px" color="#171923">
                  {tempInheritorInfo?.phoneNumber
                    ? formatPhoneNumber(tempInheritorInfo.phoneNumber)
                    : tempInheritorInfo?.phoneNumber}
                </Text>
              </VStack>
            </HStack>
          </VStack>
        </Flex>
        <Divider color="#CBD5E0" />
        <HStack spacing={2} align="center" w={isMobile ? "full" : "auto"}>
          <MdLock size="32px" color="#48BB78" />
          <Text fontSize="21px" color="#171923" fontWeight={500} lineHeight="56px">
            We use advanced encryption standards to secure your information.
          </Text>
        </HStack>
      </Flex>
    </Page>
  );
}
