import { DocumentType } from "@trustribbon/ribbon-ec-types";
import { Stepper } from "@shared/components/Stepper/Stepper.component";
import { notificationSteps } from "@config/notification-steps.config";
import { ROUTES, ROUTE_LOADER_IDS } from "@config/constants.config";
import { UploadDocumentForm } from "@components/Forms/UploadForm.component";
import { Page } from "@components/Layouts/Page/Page.component";
import { VStack } from "@chakra-ui/react";

type UploadDocumentTemplateProps = {
  documentType: DocumentType;
  uploadFormTitle: string;
  uploadFormLabel: string;
  uploadFormImagePath: string;
  isRequired: boolean;
  navigateToOnSubmit?: (typeof ROUTES)[keyof typeof ROUTES];
};

export function UploadDocumentTemplate({
  documentType,
  uploadFormTitle,
  uploadFormLabel,
  uploadFormImagePath,
  isRequired,
  navigateToOnSubmit,
}: Readonly<UploadDocumentTemplateProps>) {
  return (
    <Page
      title={uploadFormTitle}
      routeId={ROUTE_LOADER_IDS.ESTATE_SERVICES}
      pageMainProps={{ bg: "#fff" }}
    >
      <VStack gap={10} width="100%" alignItems="flex-start" px={12}>
        <Stepper steps={notificationSteps} />
        <UploadDocumentForm
          documentType={documentType}
          uploadFormLabel={uploadFormLabel}
          uploadFormImagePath={uploadFormImagePath}
          navigateToOnSubmit={navigateToOnSubmit}
          isRequired={isRequired}
        />
      </VStack>
    </Page>
  );
}
