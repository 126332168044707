import { useRef, useState } from "react";
import {
  Badge,
  Divider,
  Flex,
  HStack,
  Heading,
  Text,
  VStack,
  Alert,
  AlertIcon,
  AlertDescription,
  AlertTitle,
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  OrderedList,
  ListItem,
  Icon,
  ListIcon,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Spacer,
  Stack,
} from "@chakra-ui/react";
import { ROUTE_LOADER_IDS } from "@config/constants.config";
import { useCheckStatusInfoStore } from "@store/checkStatus.store";
import { useIsMobile } from "@shared/hooks/useIsMobile.hook";
import { Page } from "@components/Layouts/Page/Page.component";
import { AccountTransferStatus } from "@src/types";
import WithdrawalRequest from "@src/templates/CheckSubmittedInfo/components/WithdrawalRequest.checkSubmittedInfo";
import { formatPhoneNumber } from "@shared/utils/string.utils";
import { Button } from "@shared/components/Button/Button.component";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { CustomSelect } from "@shared/components/FormComponents/CustomSelect.component";
import { AiOutlinePaperClip } from "react-icons/ai";
import { MdCheckCircle } from "react-icons/md";
import InheritanceVaultAlert from "@src/templates/CheckSubmittedInfo/components/InheritanceVaultAlert.checkSubmitted";

export default function CheckSubmittedInfoTemplate() {
  const isMobile = useIsMobile();
  const { checkStatusResponse } = useCheckStatusInfoStore();
  const valuesRef = useRef({ checkStatusResponse });
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);

  const { checkStatusResponse: tempCheckStatusResponse } = valuesRef.current;

  const checkInheritanceCaseID = tempCheckStatusResponse?.id ?? "N/A";

  const CASE_STATUS_STYLE_MAP: Record<
    string,
    { textColor: string; bgColor: string; label: string }
  > = {
    submitted: { textColor: "#744210", bgColor: "#FEEBCB", label: "Submitted" },
    needs_info: { textColor: "#822727", bgColor: "#FED7D7", label: "Needs Info" },
    under_review: { textColor: "#2A4365", bgColor: "#BEE3F8", label: "Under Review" },
    confirmed: {
      textColor: "#22543D",
      bgColor: "#C6F6D5",
      label: "Confirmed Information",
    },
    transfer_completed: { textColor: "#22543D", bgColor: "#C6F6D5", label: "Transfer Completed" },
    default: { textColor: "#22543D", bgColor: "#E6E6E6", label: "Unknown Status" },
  };

  const defaultValues: {
    document: string;
  } = {
    document: "",
  };

  const formMethods = useForm<{
    document: string;
  }>({
    defaultValues:
      {
        document: "",
      } ?? defaultValues,
    // resolver: yupResolver(yup.object().shape()), //TODO will need to revisit this
  });
  const documentValue = useWatch({
    control: formMethods.control,
    name: "document",
  });

  const inputRef = useRef<HTMLInputElement>(null);

  const handleUpload = () => {
    inputRef.current?.click();
  };
  const handleDisplayFileDetails = () => {
    setIsLoading(true);
    inputRef.current?.files && setUploadedFileName(inputRef.current.files[0].name);
    setIsLoading(false);
  };

  return (
    <Page
      title="Notification Status"
      routeId={ROUTE_LOADER_IDS.ESTATE_SERVICES}
      pageMainProps={{ bg: "#fff" }}
    >
      <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Please upload the listed documents</ModalHeader>
          <ModalBody>
            <FormProvider {...formMethods} control={formMethods.control}>
              <VStack>
                <HStack justifyContent={"space-between"} w={"full"}>
                  <VStack w={"full"}>
                    <Box display={"flex"} w={"100%"} justifyContent={"space-between"}>
                      <Box w={"70%"}>
                        <CustomSelect
                          isEditing={true}
                          name={`document`}
                          placeholder="Choose type of document"
                          registerOptions={{ required: true }}
                          closeMenuOnSelect
                          options={[
                            {
                              value: "certificate_of_trust",
                              label: "Certificate of Trust",
                            },
                            // {
                            //   value: "death_certificate",
                            //   label: "Death Certificate",
                            // },
                            // {
                            //   value: "government_id",
                            //   label: "Government ID",
                            // },
                            {
                              value: "letter_of_administration",
                              label: "Letter of Administration",
                            },
                            {
                              value: "trust_agreement",
                              label: "Trust Agreement",
                            },

                            {
                              value: "letter_of_testamentary",
                              label: "Letters of Testamentary",
                            },
                          ]}
                          inputGroupProps={{
                            sx: {
                              "& .react-select": {
                                width: "100%",
                                height: "48px",
                                "& > div": {
                                  height: "48px",
                                },
                              },
                            },
                            borderRadius: "6px",
                          }}
                          chakraStyles={{
                            option: styles => ({
                              ...styles,
                            }),
                            dropdownIndicator: provided => ({
                              ...provided,
                              px: 4,
                            }),
                          }}
                        />
                      </Box>

                      <Button
                        onClick={handleUpload}
                        isLoading={isLoading}
                        isDisabled={!documentValue}
                      >
                        Upload
                      </Button>
                      <input
                        style={{ display: "none" }}
                        ref={inputRef}
                        onChange={handleDisplayFileDetails}
                        className="d-none"
                        type="file"
                      />
                    </Box>
                    {/* {uploadedFileName ? (
                      <Box w={"full"} display={"flex"}>
                        <Icon as={AiOutlinePaperClip} w={"18px"} h={"23px"} pt={"5px"} />
                        <Text fontWeight={600} fontSize={"md"}>
                          {uploadedFileName}
                        </Text>
                      </Box>
                    ) : null} */}
                  </VStack>
                </HStack>
                <Divider color="#CBD5E0" py={2} />
                <HStack width={"full"}>
                  <Text fontSize={{ base: "md", sm: "lg" }} fontWeight={600}>
                    Missing documents:
                  </Text>
                </HStack>
                <HStack width={"full"}>
                  <OrderedList>
                    <ListItem>
                      <VStack w={"100%"} alignItems={"flex-start"}>
                        <HStack>
                          <Text {...(uploadedFileName ? { as: "del" } : {})}>
                            Letter of Administration
                          </Text>
                          {uploadedFileName ? (
                            <ListIcon
                              as={MdCheckCircle}
                              color="green.500"
                              textAlign={"center"}
                              size={"md"}
                            />
                          ) : null}
                        </HStack>

                        <Text>
                          <Text as={"span"} fontWeight={600}>
                            Justification:
                          </Text>{" "}
                          Beneficiary not listed on the account. Need proof of authority to manage
                          the asset.
                        </Text>
                      </VStack>
                    </ListItem>
                  </OrderedList>
                </HStack>
                <Divider color="#CBD5E0" py={2} />
                {uploadedFileName ? (
                  <VStack spacing={4} w={"full"} alignItems={"flex-start"}>
                    <HStack width={"full"}>
                      <Text fontSize={{ base: "md", sm: "lg" }} fontWeight={600}>
                        Added documents:
                      </Text>
                    </HStack>
                    <HStack
                      gap="8px"
                      flex="1 0 auto"
                      borderRadius="8px"
                      border="1px solid var(--chakra-colors-gray-300, #CBD5E0)"
                      background="var(--chakra-colors-white, #FFF)"
                      justify="space-between"
                    >
                      <VStack align="flex-start" gap="0" p={2}>
                        <Box w={"full"} display={"flex"}>
                          <Icon as={AiOutlinePaperClip} w={"18px"} h={"23px"} pt={"5px"} />
                          <Text fontWeight={600} fontSize={"md"}>
                            {uploadedFileName}
                          </Text>
                        </Box>
                      </VStack>
                    </HStack>
                  </VStack>
                ) : null}
              </VStack>
            </FormProvider>{" "}
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={() => {
                formMethods.reset({});
                setUploadedFileName(null);
                onClose();
              }}
              isLoading={isLoading}
              mr={3}
              variant="whiteLabelOutline"
            >
              Cancel
            </Button>
            <Button
              variant={"whiteLabel"}
              onClick={() => null}
              isDisabled={!uploadedFileName}
              isLoading={isLoading}
              _hover={{
                color: "white",
                bg: "whiteLabel.secondary",
                borderColor: "whiteLabel.secondary",
              }}
            >
              Add Documents
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Flex direction="column">
        <Flex direction="column" pb={6} px={isMobile ? 2 : 12}>
          {tempCheckStatusResponse?.status &&
          ["needs_info"].includes(tempCheckStatusResponse?.status) ? (
            <HStack>
              <Alert status="warning">
                <AlertIcon />
                <HStack justifyContent={"space-between"} w={"full"}>
                  <Box>
                    <AlertTitle>
                      Required document: <strong>Letters of Administration</strong>
                    </AlertTitle>
                    <AlertDescription>
                      Beneficiary not listed on the account. Need proof of authority to manage the
                      asset.
                    </AlertDescription>
                  </Box>
                  <Button onClick={onOpen}>Upload document</Button>
                </HStack>
              </Alert>
            </HStack>
          ) : null}

          {tempCheckStatusResponse?.status &&
            ["confirmed"].includes(tempCheckStatusResponse?.status) && (
              <Stack>
                <Flex direction="column">
                  <HStack maxW="full">
                    <Heading fontSize="24" fontWeight={600} lineHeight="36px" color="#171923">
                      Your inheritance vault
                    </Heading>
                  </HStack>
                  <Divider color="#CBD5E0" />
                </Flex>
                <Flex gap={4} direction="column" py={2}>
                  <InheritanceVaultAlert />
                </Flex>
              </Stack>
            )}
          {tempCheckStatusResponse?.status &&
          ["confirmed", "transfer_completed"].includes(tempCheckStatusResponse?.status) ? (
            <Accordion allowToggle defaultIndex={[0]} mt={2}>
              <AccordionItem borderTop={"white"} borderBottom={"none"}>
                <AccordionButton px={0} py={0}>
                  <Heading fontSize="24" fontWeight={600} lineHeight="36px" color="#171923">
                    Notification details
                  </Heading>
                  <Spacer />
                  <AccordionIcon fontSize={"32px"} />
                </AccordionButton>
                <Divider color="#CBD5E0" />
                <AccordionPanel pb={2} px={0}>
                  <HStack gap={6} align="flex-start" width="100%" flexWrap="wrap" pb={2}>
                    <VStack flexBasis="71%" flexGrow={1} alignItems="flex-start">
                      <Text
                        data-testid="check-inheritance-case-id"
                        fontSize={"md"}
                        fontWeight={600}
                      >
                        Case ID #{checkInheritanceCaseID}
                      </Text>
                    </VStack>
                    <HStack gap={3} flexBasis="20%" flexGrow={1} alignItems="center">
                      <Text fontSize={"md"} fontWeight={600}>
                        Status:
                      </Text>
                      <Badge
                        fontSize={"sm"}
                        borderRadius={"sm"}
                        color={
                          CASE_STATUS_STYLE_MAP[tempCheckStatusResponse?.status ?? "default"]
                            ?.textColor
                        }
                        bg={
                          CASE_STATUS_STYLE_MAP[tempCheckStatusResponse?.status ?? "default"]
                            ?.bgColor
                        }
                        textTransform="none"
                      >
                        {
                          AccountTransferStatus[
                            (tempCheckStatusResponse?.status as keyof typeof AccountTransferStatus) ??
                              "submitted"
                          ]
                        }
                      </Badge>
                    </HStack>
                  </HStack>

                  <VStack width="100%" gap={6} w={"full"}>
                    <HStack gap={6} align="flex-start" direction="row" width="100%" flexWrap="wrap">
                      <VStack flexBasis="20%" flexGrow={1} alignItems="flex-start">
                        <Text fontSize="16px" lineHeight="24px" color="#718096" whiteSpace="nowrap">
                          Deceased First Name:
                        </Text>
                        <Text fontSize="16px" fontWeight={600} lineHeight="24px" color="#171923">
                          {tempCheckStatusResponse?.deceasedPerson?.firstName}
                        </Text>
                      </VStack>
                      <VStack flexBasis="20%" flexGrow={1} alignItems="flex-start">
                        <Text fontSize="16px" lineHeight="24px" color="#718096" whiteSpace="nowrap">
                          Deceased Middle Name:
                        </Text>
                        <Text fontSize="16px" fontWeight={600} lineHeight="24px" color="#171923">
                          {tempCheckStatusResponse?.deceasedPerson?.middleName}
                        </Text>
                      </VStack>
                      <VStack flexBasis="20%" flexGrow={1} alignItems="flex-start">
                        <Text fontSize="16px" lineHeight="24px" color="#718096" whiteSpace="nowrap">
                          Deceased Last Name:
                        </Text>
                        <Text fontSize="16px" fontWeight={600} lineHeight="24px" color="#171923">
                          {tempCheckStatusResponse?.deceasedPerson?.lastName}
                        </Text>
                      </VStack>
                      <VStack flexBasis="20%" flexGrow={1} alignItems="flex-start">
                        <Text fontSize="16px" lineHeight="24px" color="#718096" whiteSpace="nowrap">
                          {isMobile ? "SSN" : "Social Security Number"}:
                        </Text>
                        <Text
                          fontSize="16px"
                          fontWeight={600}
                          lineHeight="24px"
                          color="#171923"
                          whiteSpace="nowrap"
                        >
                          {tempCheckStatusResponse?.deceasedPerson?.ssn}
                        </Text>
                      </VStack>
                    </HStack>
                    <HStack gap={6} align="flex-start" direction="row" width="100%" flexWrap="wrap">
                      <VStack flexBasis="20%" flexGrow={1} alignItems="flex-start">
                        <Text fontSize="16px" lineHeight="24px" color="#718096" whiteSpace="nowrap">
                          Inheritor First Name:
                        </Text>
                        <Text fontSize="16px" fontWeight={600} lineHeight="24px" color="#171923">
                          {tempCheckStatusResponse?.inheritor?.firstName}
                        </Text>
                      </VStack>
                      <VStack flexBasis="20%" flexGrow={1} alignItems="flex-start">
                        <Text fontSize="16px" lineHeight="24px" color="#718096" whiteSpace="nowrap">
                          Inheritor Middle Name:
                        </Text>
                        <Text fontSize="16px" fontWeight={600} lineHeight="24px" color="#171923">
                          {tempCheckStatusResponse?.inheritor?.middleName}
                        </Text>
                      </VStack>
                      <VStack flexBasis="20%" flexGrow={1} alignItems="flex-start">
                        <Text fontSize="16px" lineHeight="24px" color="#718096" whiteSpace="nowrap">
                          Inheritor Last Name:
                        </Text>
                        <Text fontSize="16px" fontWeight={600} lineHeight="24px" color="#171923">
                          {tempCheckStatusResponse?.inheritor?.lastName}
                        </Text>
                      </VStack>
                      <VStack flexBasis="20%" flexGrow={1} alignItems="flex-start">
                        <Text fontSize="16px" lineHeight="24px" color="#718096" whiteSpace="nowrap">
                          {isMobile ? "SSN" : "Social Security Number"}:
                        </Text>
                        <Text fontSize="16px" fontWeight={600} lineHeight="24px" color="#171923">
                          {tempCheckStatusResponse?.inheritor?.ssn}
                        </Text>
                      </VStack>
                    </HStack>
                    <HStack gap={6} align="flex-start" direction="row" width="100%" flexWrap="wrap">
                      <VStack alignItems="flex-start" flexBasis="23%">
                        <Text fontSize="16px" lineHeight="24px" color="#718096" whiteSpace="nowrap">
                          Inheritor Email Address:
                        </Text>
                        <Text
                          fontSize="16px"
                          fontWeight={600}
                          lineHeight="24px"
                          color="#171923"
                          whiteSpace="nowrap"
                        >
                          {tempCheckStatusResponse?.inheritor?.email}
                        </Text>
                      </VStack>
                      <VStack alignItems="flex-start" flexBasis="auto" flexGrow={1}>
                        <Text fontSize="16px" lineHeight="24px" color="#718096" whiteSpace="nowrap">
                          Inheritor Phone Number:
                        </Text>
                        <Text fontSize="16px" fontWeight={600} lineHeight="24px" color="#171923">
                          {tempCheckStatusResponse?.inheritor?.phoneNumber
                            ? formatPhoneNumber(tempCheckStatusResponse?.inheritor?.phoneNumber)
                            : tempCheckStatusResponse?.inheritor?.phoneNumber}
                        </Text>
                      </VStack>
                    </HStack>
                  </VStack>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          ) : (
            <>
              <Flex direction="column" pt={6} pb={2}>
                <HStack>
                  <Heading fontSize="24" fontWeight={600} lineHeight="36px" color="#171923">
                    Notification details
                  </Heading>
                </HStack>
                <Divider color="#CBD5E0" />
              </Flex>
              <HStack
                gap={6}
                align="flex-start"
                direction="row"
                width="100%"
                flexWrap="wrap"
                pb={2}
              >
                <VStack flexBasis="71%" flexGrow={1} alignItems="flex-start">
                  <Text data-testid="check-inheritance-case-id" fontSize={"md"} fontWeight={600}>
                    Case ID #{checkInheritanceCaseID}
                  </Text>
                </VStack>
                <HStack gap={3} flexBasis="26%" flexGrow={1} alignItems="center" pl={3}>
                  <Text fontSize={"md"} fontWeight={600}>
                    Status:
                  </Text>
                  <Badge
                    fontSize={"sm"}
                    borderRadius={"sm"}
                    color={
                      CASE_STATUS_STYLE_MAP[tempCheckStatusResponse?.status ?? "default"]?.textColor
                    }
                    bg={
                      CASE_STATUS_STYLE_MAP[tempCheckStatusResponse?.status ?? "default"]?.bgColor
                    }
                    textTransform="none"
                  >
                    {
                      AccountTransferStatus[
                        (tempCheckStatusResponse?.status as keyof typeof AccountTransferStatus) ??
                          "submitted"
                      ]
                    }
                  </Badge>
                </HStack>
              </HStack>
              <VStack width="100%" gap={6} w={"full"}>
                <HStack align="flex-start" direction="row" width="100%" flexWrap="wrap" gap={0}>
                  <VStack flexBasis="20%" flexGrow={1} alignItems="flex-start">
                    <Text fontSize="16px" lineHeight="24px" color="#718096" whiteSpace="nowrap">
                      Deceased First Name:
                    </Text>
                    <Text fontSize="16px" fontWeight={600} lineHeight="24px" color="#171923">
                      {tempCheckStatusResponse?.deceasedPerson?.firstName}
                    </Text>
                  </VStack>
                  <VStack flexBasis="20%" flexGrow={1} alignItems="flex-start">
                    <Text fontSize="16px" lineHeight="24px" color="#718096" whiteSpace="nowrap">
                      Deceased Middle Name:
                    </Text>
                    <Text fontSize="16px" fontWeight={600} lineHeight="24px" color="#171923">
                      {tempCheckStatusResponse?.deceasedPerson?.middleName}
                    </Text>
                  </VStack>
                  <VStack flexBasis="20%" flexGrow={1} alignItems="flex-start">
                    <Text fontSize="16px" lineHeight="24px" color="#718096" whiteSpace="nowrap">
                      Deceased Last Name:
                    </Text>
                    <Text fontSize="16px" fontWeight={600} lineHeight="24px" color="#171923">
                      {tempCheckStatusResponse?.deceasedPerson?.lastName}
                    </Text>
                  </VStack>
                  <VStack flexBasis="20%" flexGrow={1} alignItems="flex-start">
                    <Text fontSize="16px" lineHeight="24px" color="#718096" whiteSpace="nowrap">
                      {isMobile ? "SSN" : "Social Security Number"}:
                    </Text>
                    <Text
                      fontSize="16px"
                      fontWeight={600}
                      lineHeight="24px"
                      color="#171923"
                      whiteSpace="nowrap"
                    >
                      {tempCheckStatusResponse?.deceasedPerson?.ssn}
                    </Text>
                  </VStack>
                </HStack>
                <HStack align="flex-start" direction="row" width="100%" flexWrap="wrap" gap={0}>
                  <VStack flexBasis="20%" flexGrow={1} alignItems="flex-start">
                    <Text fontSize="16px" lineHeight="24px" color="#718096" whiteSpace="nowrap">
                      Inheritor First Name:
                    </Text>
                    <Text fontSize="16px" fontWeight={600} lineHeight="24px" color="#171923">
                      {tempCheckStatusResponse?.inheritor?.firstName}
                    </Text>
                  </VStack>
                  <VStack flexBasis="20%" flexGrow={1} alignItems="flex-start">
                    <Text fontSize="16px" lineHeight="24px" color="#718096" whiteSpace="nowrap">
                      Inheritor Middle Name:
                    </Text>
                    <Text fontSize="16px" fontWeight={600} lineHeight="24px" color="#171923">
                      {tempCheckStatusResponse?.inheritor?.middleName}
                    </Text>
                  </VStack>
                  <VStack flexBasis="20%" flexGrow={1} alignItems="flex-start">
                    <Text fontSize="16px" lineHeight="24px" color="#718096" whiteSpace="nowrap">
                      Inheritor Last Name:
                    </Text>
                    <Text fontSize="16px" fontWeight={600} lineHeight="24px" color="#171923">
                      {tempCheckStatusResponse?.inheritor?.lastName}
                    </Text>
                  </VStack>
                  <VStack flexBasis="20%" flexGrow={1} alignItems="flex-start">
                    <Text fontSize="16px" lineHeight="24px" color="#718096" whiteSpace="nowrap">
                      {isMobile ? "SSN" : "Social Security Number"}:
                    </Text>
                    <Text fontSize="16px" fontWeight={600} lineHeight="24px" color="#171923">
                      {tempCheckStatusResponse?.inheritor?.ssn}
                    </Text>
                  </VStack>
                </HStack>
                <HStack align="flex-start" direction="row" width="100%" flexWrap="wrap" gap={0}>
                  <VStack alignItems="flex-start" flexBasis="23%">
                    <Text fontSize="16px" lineHeight="24px" color="#718096" whiteSpace="nowrap">
                      Inheritor Email Address:
                    </Text>
                    <Text
                      fontSize="16px"
                      fontWeight={600}
                      lineHeight="24px"
                      color="#171923"
                      whiteSpace="nowrap"
                    >
                      {tempCheckStatusResponse?.inheritor?.email}
                    </Text>
                  </VStack>
                  <VStack alignItems="flex-start" flexBasis="auto" flexGrow={1}>
                    <Text fontSize="16px" lineHeight="24px" color="#718096" whiteSpace="nowrap">
                      Inheritor Phone Number:
                    </Text>
                    <Text fontSize="16px" fontWeight={600} lineHeight="24px" color="#171923">
                      {tempCheckStatusResponse?.inheritor?.phoneNumber
                        ? formatPhoneNumber(tempCheckStatusResponse?.inheritor?.phoneNumber)
                        : tempCheckStatusResponse?.inheritor?.phoneNumber}
                    </Text>
                  </VStack>
                </HStack>
              </VStack>
            </>
          )}
        </Flex>
        {tempCheckStatusResponse?.status &&
        ["confirmed"].includes(tempCheckStatusResponse?.status) ? (
          <WithdrawalRequest checkStatusResponse={tempCheckStatusResponse} />
        ) : (
          <Flex direction="column" pt={4} px={isMobile ? 2 : 12}>
            <Flex direction="column" pb={2}>
              <HStack>
                <Heading fontSize={24} fontWeight={600} color="#171923">
                  Distribute funds
                </Heading>
              </HStack>
              <Divider color="#CBD5E0" />
            </Flex>
            <Flex gap={4} direction="column" pb={4}>
              <Alert status="info" variant="top-accent" borderRadius={0} bg={"#EBF8FF"}>
                <AlertIcon />
                <Text
                  fontSize={{ base: "md", sm: "lg" }}
                  color="#2D3748"
                  fontWeight={400}
                  lineHeight="20px"
                >
                  Once your case has been confirmed, you can choose how you want the funds to be
                  distributed.
                </Text>
              </Alert>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Page>
  );
}
