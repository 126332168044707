import { ROUTES } from "@config/constants.config";
import { UploadDocumentTemplate } from "@templates/UploadDocument/UploadDocument.template";

export default function UploadDeathCertificatePage() {
  return (
    <UploadDocumentTemplate
      documentType="death-certificates"
      uploadFormTitle="Upload Death Certificate"
      uploadFormLabel="Upload Death Certificate"
      uploadFormImagePath="https://static.trustribbon.com/assets/animations/death-certificate-icon.png"
      isRequired={true}
      navigateToOnSubmit={ROUTES.UPLOAD_GOV_ID}
    />
  );
}
