import { useEffect, useState } from "react";
import { useBlocker } from "react-router-dom";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { Button } from "@shared/components/Button/Button.component";

export function BlockerModal() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const blocker = useBlocker(({ nextLocation }) => {
    return nextLocation.state?.shouldBlock;
  });

  useEffect(() => {
    if (blocker.state === "blocked") {
      setIsModalOpen(true);
    }
  }, [blocker]);

  const onClose = () => {
    setIsModalOpen(false);

    return blocker.reset?.();
  };

  const onConfirm = () => {
    setIsModalOpen(false);

    return blocker.proceed?.();
  };

  return (
    <Modal isOpen={isModalOpen} onClose={onClose} closeOnOverlayClick={false} isCentered>
      <ModalOverlay />
      <ModalContent bg="white" margin={{ base: 4, md: 0 }}>
        <ModalHeader>Confirm Exit</ModalHeader>
        <ModalBody>Are you sure you want to leave this page?</ModalBody>
        <ModalFooter>
          <Button
            size="lg"
            borderRadius={"md"}
            border="1.9px solid"
            borderColor="whiteLabel.primary"
            _hover={{
              color: "white",
              bg: "whiteLabel.secondary",
              border: "1.9px solid",
              borderColor: "whiteLabel.primary",
            }}
            variant="whiteLabel"
            mr={3}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            size="lg"
            borderRadius="md"
            variant="whiteLabelGhost"
            border="1.9px solid"
            borderColor="whiteLabel.primary"
            _hover={{
              color: "white",
              bg: "whiteLabel.secondary",
              border: "1.9px solid",
              borderColor: "whiteLabel.secondary",
            }}
            color="whiteLabel.primary"
            onClick={onConfirm}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
