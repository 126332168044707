import { Box, HStack, Icon, List, ListItem, Stack, Text } from "@chakra-ui/react";
import { FiCheck } from "react-icons/fi";

export default function PromoBox() {
  return (
    <Box
      maxW="full"
      borderWidth="1px"
      borderRadius="md"
      overflow="hidden"
      bg={"gray.50"}
      p={4}
      mb={4}
    >
      <Stack spacing={2}>
        <Text fontSize={{ base: "md", md: 20 }} fontWeight={600}>
          Promotional offer open an account and receive a{" "}
          <Text as={"span"} fontWeight={600} fontSize={{ base: "md", md: 20 }} color={"green.500"}>
            $300
          </Text>{" "}
          cash bonus.
        </Text>
        <HStack spacing={10} justifyContent={"space-between"}>
          <Box>
            <List spacing={2}>
              {[
                "No minimum balance + no monthly fees",
                `Earn up to 5.00% APY`,
                "Up to 2 days early pay with direct deposit",
              ].map(feature => (
                <ListItem key={feature} color="fg.muted">
                  <HStack spacing="4">
                    <Icon as={FiCheck} color="green.500" boxSize="4" />
                    <Text fontSize={{ base: "sm", md: "sm" }}>{feature}</Text>
                  </HStack>
                </ListItem>
              ))}
            </List>
          </Box>
        </HStack>
      </Stack>
    </Box>
  );
}
