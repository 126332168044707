import { SimpleGrid, Stack, Divider, Box, Text, Button } from "@chakra-ui/react";
import { CustomSelect } from "@shared/components/FormComponents/CustomSelect.component";
import { useIsMobile } from "@shared/hooks/useIsMobile.hook";
import { formatNumberToCurrency } from "@shared/utils/string.utils";
import { FinancialAccountAllocationWithAccountNumberAndType } from "@trustribbon/ribbon-ec-types";

interface WithdrawalRequestSectionProps {
  numberOfAccountAllocation: number;
  rowId: number;
  accountAllocation: FinancialAccountAllocationWithAccountNumberAndType & {
    isLiability?: boolean;
  };
}

const buttonProps = {
  size: "lg",
  border: "1px solid",
  borderColor: "whiteLabel.primary",
  fontSize: { base: "sm", md: "18px" },
  fontWeight: 700,
  lineHeight: "24px",
  variant: "whiteLabel",
  _hover: {
    color: "white",
    bg: "whiteLabel.secondary",
    border: "1.9px solid",
    borderColor: "whiteLabel.secondary",
  },
};

export default function WithdrawalRequestSection({
  accountAllocation,
  rowId,
  numberOfAccountAllocation,
}: WithdrawalRequestSectionProps) {
  const isMobile = useIsMobile();

  return (
    <SimpleGrid
      columns={[1, null, 4]}
      spacing="10px"
      minChildWidth={{ base: "full", md: "200px" }}
      maxW={"full"}
    >
      <Stack>
        {rowId === 0 && !isMobile && (
          <Box as="section" display={"flex"} justifyContent={"flex-start"} alignItems={"center"}>
            <Text
              textTransform={"uppercase"}
              fontSize={"md"}
              fontWeight={"bold"}
              color={"gray.500"}
            >
              {accountAllocation.isLiability ? "loan type" : "account type"}
            </Text>
          </Box>
        )}
        <Box
          as="section"
          bg="blackAlpha.50"
          p={2}
          borderWidth="1px"
          borderRadius={"lg"}
          display={"flex"}
          justifyContent={"flex-start"}
          alignItems={"center"}
        >
          <Text fontWeight={"bold"} fontSize={"md"}>
            {accountAllocation.accountType
              ? accountAllocation.accountType[0].toUpperCase() +
                accountAllocation.accountType.slice(1)
              : "Not specified"}
          </Text>
        </Box>
      </Stack>
      <Stack>
        {rowId === 0 && !isMobile && (
          <Box as="section" display={"flex"} justifyContent={"flex-start"} alignItems={"center"}>
            <Text
              textTransform={"uppercase"}
              fontSize={"md"}
              fontWeight={"bold"}
              color={"gray.500"}
            >
              {accountAllocation.isLiability ? "loan amount" : "available amount"}
            </Text>
          </Box>
        )}
        <Box
          as="section"
          bg="blackAlpha.50"
          p={2}
          borderWidth="1px"
          borderRadius={"lg"}
          display={"flex"}
          justifyContent={"flex-start"}
          alignItems={"center"}
        >
          <Text fontWeight={"bold"} fontSize={"md"}>
            {`${accountAllocation.amount && !Number.isNaN(accountAllocation.amount) ? formatNumberToCurrency(Number(accountAllocation.amount)) : "-"}`}
          </Text>
        </Box>
      </Stack>
      {accountAllocation.isLiability ? (
        <Box mt={6}>
          <Stack>
            <Button width={"full"} {...buttonProps} type={"submit"}>
              {"View options"}
            </Button>
          </Stack>
        </Box>
      ) : (
        <Stack>
          {rowId === 0 && !isMobile && (
            <Box as="section" display={"flex"} justifyContent={"flex-start"} alignItems={"center"}>
              <Text
                textTransform={"uppercase"}
                fontSize={"md"}
                fontWeight={"bold"}
                color={"gray.500"}
              >
                withdrawal type
              </Text>
            </Box>
          )}
          <Box>
            <CustomSelect
              isEditing={true}
              name={`finAccountAllocations.${rowId}.distributionMethod`}
              placeholder="Distribute Funds"
              registerOptions={{ required: true }}
              defaultValue={
                accountAllocation.distributionMethod &&
                accountAllocation.distributionMethod !== "pending"
                  ? {
                      label: [
                        {
                          value: "open_account",
                          label: "Open an account",
                        },
                        {
                          value: "requested_check",
                          label: "Receive a check",
                        },
                        {
                          value: "transfer_money",
                          label: "Transfer to another institution",
                        },
                      ].find(v => v.value === accountAllocation.distributionMethod)!.label!,
                      value: accountAllocation.distributionMethod,
                    }
                  : {
                      label: "Open an account",
                      value: "open_account",
                    }
              }
              closeMenuOnSelect
              options={[
                {
                  value: "open_account",
                  label: "Open an account",
                },
                {
                  value: "requested_check",
                  label: "Receive a check",
                },
                {
                  value: "transfer_money",
                  label: "Transfer to another institution",
                },
              ]}
              inputGroupProps={{
                sx: {
                  "& .react-select": {
                    width: "100%",
                    height: "48px",
                    "& > div": {
                      height: "48px",
                    },
                  },
                },
                borderRadius: "6px",
              }}
              chakraStyles={{
                option: styles => ({
                  ...styles,
                  backgroundColor: "#F8F8F8",
                }),
                dropdownIndicator: provided => ({
                  ...provided,
                  px: 4,
                }),
              }}
            />
          </Box>
        </Stack>
      )}
      {rowId !== numberOfAccountAllocation && isMobile && <Divider py={2} color="#CBD5E0" />}
    </SimpleGrid>
  );
}
