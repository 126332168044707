import { HStack } from "@chakra-ui/react";
import { FormInput, FormInputProps } from "@shared/components/FormComponents/FormInput.component";
import { ssnMask } from "@shared/config/masks.config";
import { useIsMobile } from "@shared/hooks/useIsMobile.hook";

type CheckStatusInputsProps = {
  formInputProps?: Partial<FormInputProps>;
};

export function CheckStatusInputs({ formInputProps }: Readonly<CheckStatusInputsProps>) {
  const isMobile = useIsMobile();
  const defaultFormInputProps = formInputProps ?? {
    size: "lg",
    labelProps: { backgroundColor: "#F8F8F8" },
    formControlProps: {
      width: "auto",
      flexGrow: 1,
    },
  };

  return (
    <>
      <HStack gap={6} align="flex-start" direction="row" flexWrap="wrap">
        <FormInput
          data-testid="deceased-ssn-input"
          label={isMobile ? "Deceased SSN" : "Deceased Social Security Number"}
          name="deceasedSsn"
          type="password"
          variant="whiteLabel"
          mask={ssnMask}
          maxLength={11}
          registerOptions={{ required: true }}
          {...defaultFormInputProps}
        />
        <FormInput
          data-testid="inheritor-ssn-input"
          label={isMobile ? "Your SSN" : "Your Social Security Number"}
          name="inheritorSsn"
          type="password"
          variant="whiteLabel"
          mask={ssnMask}
          maxLength={11}
          registerOptions={{ required: true }}
          {...defaultFormInputProps}
        />
      </HStack>
      <HStack
        pr={3}
        align="flex-start"
        direction="row"
        flexWrap="wrap"
        width={isMobile ? "100%" : "50%"}
      >
        <FormInput
          data-testid="deceased-email-input"
          label="Your Email Address"
          name="email"
          registerOptions={{ required: true }}
          {...formInputProps}
        />
      </HStack>
    </>
  );
}
