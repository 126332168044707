import { captureException } from "@sentry/react";
import { CONSTANTS } from "@config/constants.config";
import { getCookie, getExpirationDateFromToken, setCookie } from "@shared/utils/storage.utils";
import { post } from "./base.service";

export interface Credentials {
  access_token: string;
  expires_in: number;
  token_type: string;
}

export interface Auth0TokenRequest {
  grant_type: "client_credentials";
  client_id: string;
  client_secret: string;
  audience: string;
}

export const getAccessToken = async () => {
  try {
    // get accessToken from cookies
    const cookieName = "3dj89ql0"; // random typed string for concealment
    let accessToken = getCookie(cookieName);

    if (accessToken) {
      accessToken = atob(accessToken);

      const expiresIn = getExpirationDateFromToken(accessToken);

      if (expiresIn > new Date()) {
        return accessToken;
      }

      accessToken = null;
    }

    if (!accessToken) {
      const credentials = await authenticate();

      accessToken = credentials.access_token;

      // encode accessToken and get expiration time
      const encodedAccessToken = btoa(accessToken);
      const expiresIn = credentials.expires_in;

      // set accessToken cookie
      setCookie(cookieName, encodedAccessToken, {
        expires: new Date(Date.now() + expiresIn * 1000),
      });

      return accessToken;
    }
  } catch (error) {
    captureException(error);
    throw error;
  }
};

async function authenticate() {
  try {
    if (process.env.NODE_ENV === "development")
      return { access_token: "no-token-needed", expires_in: 60 * 1 * 1000 };

    const auth0URL = CONSTANTS.VITE_AUTH_CLIENT_ISSUER_DOMAIN.startsWith("https://")
      ? CONSTANTS.VITE_AUTH_CLIENT_ISSUER_DOMAIN
      : `https://${CONSTANTS.VITE_AUTH_CLIENT_ISSUER_DOMAIN}`;
    const url = `${auth0URL}/oauth/token`;

    const data = await post<Auth0TokenRequest, Credentials>(
      url,
      {
        grant_type: "client_credentials",
        client_id: CONSTANTS.VITE_AUTH_CLIENT_ID,
        client_secret: CONSTANTS.VITE_AUTH_CLIENT_SECRET,
        audience: CONSTANTS.VITE_AUTH_CLIENT_AUDIENCE,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error) {
    captureException(error);
    throw error;
  }
}
