import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Flex, HStack, Heading, Image, Divider } from "@chakra-ui/react";
import { DocumentType } from "@trustribbon/ribbon-ec-types";
import { useDocumentStore } from "@shared/store/document.store";
import { useNavigation } from "@shared/hooks/useNavigation.hook";
import { UploadDocument } from "@shared/components/FormComponents/UploadDocument.component";
import { EnhancedFile } from "@shared/types";
import { verifyDocument } from "@services/document.service";
import { NotifyNavigationBlock } from "./Blocks/NotifyNavigation.block.component";

type FileUpload = { [documentType in DocumentType]: EnhancedFile | null };

type UploadDocumentFormProps = {
  documentType: DocumentType;
  uploadFormLabel: string;
  uploadFormImagePath: string;
  isRequired?: boolean;
  navigateToOnSubmit?: string;
};

export function UploadDocumentForm({
  documentType,
  uploadFormLabel,
  uploadFormImagePath,
  isRequired,
  navigateToOnSubmit,
}: Readonly<UploadDocumentFormProps>) {
  const { navigate } = useNavigation();
  const { document, setDocument } = useDocumentStore(state => ({
    setDocument: state.setDocument,
    document: state.document,
  }));
  const formMethods = useForm<FileUpload>({
    defaultValues: {
      [documentType]: document?.[documentType] ?? null,
    },
  });

  const { handleSubmit, formState, watch } = formMethods;
  const { errors } = formState;
  const hasErrors = !!errors["death-certificates"] || !!errors["government-ids"];
  const hasDocument = !!watch(documentType);

  const isContinueDisabled = isRequired ? hasErrors || !hasDocument : hasErrors;

  const onSubmit: SubmitHandler<FileUpload> = data => {
    setDocument(data ?? null);

    if (navigateToOnSubmit) {
      return navigate(navigateToOnSubmit);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <Flex
        as="form"
        id="upload-document-form"
        direction="column"
        onSubmit={handleSubmit(onSubmit)}
        width="100%"
      >
        <Flex gap={6} direction="column" mb={16}>
          <HStack spacing={2}>
            <Image height="48px" width="60px" src={uploadFormImagePath} />
            <Heading variant="h1" fontSize={30} fontWeight={600} lineHeight="56px">
              {uploadFormLabel}
            </Heading>
          </HStack>
          <HStack gap={6} align="flex-start" direction="row" flexWrap="wrap">
            <UploadDocument
              documentType={documentType}
              rules={{ required: isRequired && "Please upload a document" }}
              verificationService={verifyDocument}
            />
          </HStack>
        </Flex>
        <Flex gap={6} direction="column" mb={16}>
          <Divider color="#CBD5E0" />
          <NotifyNavigationBlock isDisabled={isContinueDisabled} />
        </Flex>
      </Flex>
    </FormProvider>
  );
}
