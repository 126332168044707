import { useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Divider, Flex, HStack, Heading, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCheckStatusInfoStore } from "@store/checkStatus.store";
import { ROUTES, ROUTE_LOADER_IDS } from "@config/constants.config";
import { checkInheritanceStatus } from "@services/notifyDeceasedInfo.service";
import { useInstitutionData } from "@hooks/useInstitutionData.hook";
import { useNavigation } from "@shared/hooks/useNavigation.hook";
import { useIsMobile } from "@shared/hooks/useIsMobile.hook";

import {
  CheckInheritanceStatusStore,
  checkInheritanceStatusFormSchema,
} from "@shared/config/schema.validator";
import { CheckStatusInputs } from "@shared/components/FormComponents/CheckInheritanceInputs.component";
import { cleanNumberString } from "@shared/utils/string.utils";
import { NotifyNavigationBlock } from "./Blocks/NotifyNavigation.block.component";

const defaultValues = {
  inheritorSsn: "",
  deceasedSsn: "",
  email: "",
};

const formInputProps = {
  size: "lg",
  labelProps: { backgroundColor: "#fff" },
  formControlProps: {
    width: "auto",
    flexGrow: 1,
  },
};

export function CheckInheritanceStatusForm() {
  const isMobile = useIsMobile();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({ message: "" });
  const { navigate } = useNavigation();
  const institution = useInstitutionData(ROUTE_LOADER_IDS.ESTATE_SERVICES);
  const { setCheckStatusResponse } = useCheckStatusInfoStore();

  const formMethods = useForm<CheckInheritanceStatusStore>({
    defaultValues: defaultValues,
    resolver: yupResolver(checkInheritanceStatusFormSchema),
  });

  const { handleSubmit, formState } = formMethods;
  const { errors } = formState;
  const hasErrors = !!errors.inheritorSsn || !!errors.deceasedSsn || !!errors.email;

  const onSubmit: SubmitHandler<CheckInheritanceStatusStore> = async data => {
    setIsLoading(true);

    try {
      const hasAlreadyNotified = await checkInheritanceStatus({
        institutionSubdomain: institution?.subdomain,
        deceasedPerson: {
          ssn: cleanNumberString(data.deceasedSsn),
        },
        inheritor: {
          ssn: cleanNumberString(data.inheritorSsn),
          email: data.email,
        },
      });

      if (hasAlreadyNotified) {
        setCheckStatusResponse(hasAlreadyNotified);

        return navigate(ROUTES.SUBMITTED_INFO_STATUS);
      }

      return navigate(ROUTES.DECEASED_PERSON_INFO);
    } catch (error) {
      console.error("Error", error);
      setError({ message: "An error occurred while submitting your information." });
      setIsLoading(false);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <Flex
        as="form"
        id="passed-one-form"
        direction="column"
        onSubmit={handleSubmit(onSubmit)}
        width="100%"
        pb={40}
      >
        <Flex gap={8} direction="column" mb={16} px={isMobile ? 2 : 12}>
          <Flex w={"full"} direction={"column"}>
            <Heading variant="h1" fontSize={24} fontWeight={600} lineHeight="56px">
              Check your notification status
            </Heading>
            <Divider color="#CBD5E0" />
          </Flex>

          <CheckStatusInputs formInputProps={formInputProps} />
        </Flex>
        {error?.message && (
          <Flex gap={6} direction="column">
            <HStack gap={6} align="flex-start" direction="row" width="100%" flexWrap="wrap">
              <Text fontSize="16px" fontWeight={600} lineHeight="24px" color="red">
                {error.message || "An error occurred while submitting your information."}
              </Text>
            </HStack>
          </Flex>
        )}
        <Flex gap={6} direction="column" mb={16} px={isMobile ? 2 : 12} pt={2}>
          <Divider color="#CBD5E0" />
          <NotifyNavigationBlock isDisabled={hasErrors} isLoading={isLoading} />
        </Flex>
      </Flex>
    </FormProvider>
  );
}
