import { CONSTANTS } from "@config/constants.config";

export async function baseFetcher(pathname: string) {
  return fetch(`${CONSTANTS.VITE_APP_API_URL}/${pathname}`).then(async res => {
    return res.json();
  });
}

async function api<T>(path: string, config: RequestInit): Promise<T> {
  const request = new Request(path, config);
  const response = await fetch(request);
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  // may error if there is no body, return empty array
  return response.json().catch(() => ({}));
}

export async function get<T>(path: string, config?: RequestInit): Promise<T> {
  const init = {
    method: "get",
    ...config,
  };

  return await api<T>(path, init);
}

export async function post<T, U>(path: string, body: T, config?: RequestInit): Promise<U> {
  const init = {
    method: "post",
    body: JSON.stringify(body),
    ...config,
  };
  return await api<U>(path, init);
}

export async function postBinary<T extends Blob, U>(
  path: string,
  body: T,
  config?: RequestInit
): Promise<U> {
  const init = {
    method: "post",
    body,
    ...config,
  };
  return await api<U>(path, init);
}

export async function put<T extends BodyInit, U>(
  path: string,
  body: T,
  config?: RequestInit
): Promise<U> {
  const init = { method: "put", body: body, ...config };
  return await api<U>(path, init);
}
