import { ROUTES } from "@config/constants.config";
import { UploadDocumentTemplate } from "@templates/UploadDocument/UploadDocument.template";

export default function UploadGovIdPage() {
  return (
    <UploadDocumentTemplate
      documentType="government-ids"
      uploadFormTitle="Upload Government ID"
      uploadFormLabel="Upload Government ID"
      uploadFormImagePath="https://static.trustribbon.com/assets/animations/govt-id-icon.png"
      isRequired={true}
      navigateToOnSubmit={ROUTES.UPLOAD_WILL_TEST}
    />
  );
}
