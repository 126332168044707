import { Box, Button, Card, HStack, Icon, Link, Square, Stack, Text } from "@chakra-ui/react";
import { FiFileText } from "react-icons/fi";
import { MdDownload } from "react-icons/md";

export const InheritanceVaultDocuments = () => (
  <Card variant={"outline"} p={{ base: "4", md: "6" }} pt={4}>
    <Stack spacing="5">
      <Stack spacing="1">
        <Text textStyle="lg" fontWeight={700}>
          Bills, debt, and subscriptions
        </Text>
        <Text>
          <Text as={"span"} fontWeight={700}>
            Note:{" "}
          </Text>
          Here is a list of all my outstanding debts and bills. Thank you for taking care of
          everything.
        </Text>
      </Stack>
      <Box borderWidth={{ base: "0", md: "1px" }} p={{ base: "0", md: "4" }} borderRadius="lg">
        <Stack justify="space-between" direction={{ base: "column", md: "row" }} spacing="5">
          <HStack spacing="3">
            <Square size="10" bg="bg.subtle" borderRadius="lg">
              <Icon as={FiFileText} boxSize="5" />
            </Square>
            <Box fontSize="sm">
              <Text color="empahsized" fontWeight="medium">
                abraham_financial_records.pdf
              </Text>
            </Box>
          </HStack>
          <Stack spacing="3" direction={{ base: "column-reverse", md: "row" }}>
            <Link
              target="_blank"
              href={"https://static.trustribbon.com/assets/ribbon/abraham_financial_records.pdf"}
              download="https://static.trustribbon.com/assets/ribbon/abraham_financial_records.pdf"
            >
              <Button
                variant="whiteLabel"
                border="1px solid"
                borderColor="whiteLabel.borderColor"
                fontSize="16px"
                fontWeight={600}
                rightIcon={<MdDownload />}
                w="full"
              >
                Download
              </Button>
            </Link>
          </Stack>
        </Stack>
      </Box>
      <Stack>
        <Stack spacing="1">
          <Text textStyle="lg" fontWeight={700}>
            Personal accounts
          </Text>
          <Text textStyle="sm" color="fg.muted">
            <Text as={"span"} fontWeight={700}>
              Note:{" "}
            </Text>
            Current social media and email accounts.
          </Text>
        </Stack>
        <Box borderWidth={{ base: "0", md: "1px" }} p={{ base: "0", md: "4" }} borderRadius="lg">
          <Stack justify="space-between" direction={{ base: "column", md: "row" }} spacing="5">
            <HStack spacing="3">
              <Square size="10" bg="bg.subtle" borderRadius="lg">
                <Icon as={FiFileText} boxSize="5" />
              </Square>
              <Box fontSize="sm">
                <Text color="empahsized" fontWeight="medium">
                  abraham_personal_accounts.pdf
                </Text>
              </Box>
            </HStack>
            <Stack spacing="3" direction={{ base: "column-reverse", md: "row" }}>
              <Link
                target="_blank"
                href={"https://static.trustribbon.com/assets/ribbon/abraham_personal_accounts.pdf"}
                download="https://static.trustribbon.com/assets/ribbon/abraham_personal_accounts.pdf"
              >
                <Button
                  variant="whiteLabel"
                  border="1px solid"
                  borderColor="whiteLabel.borderColor"
                  fontSize="16px"
                  fontWeight={600}
                  rightIcon={<MdDownload />}
                  w="full"
                >
                  Download
                </Button>
              </Link>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Stack>
  </Card>
);
