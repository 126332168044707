import { captureException } from "@sentry/react";
import {
  NotifyDeceasedPersonRequest,
  NotifyDeceasedPersonResponse,
  SearchSubmittedInheritanceCaseRequest,
  SearchSubmittedInheritanceCaseResponse,
} from "@trustribbon/ribbon-ec-types";
import { CONSTANTS } from "@config/constants.config";
import { cleanNumberString } from "@shared/utils/string.utils";
import { getAccessToken } from "./authenticate.service";
import { post } from "./base.service";

export const notifyDeceasedInfo = async (
  notificationInfo: NotifyDeceasedPersonRequest
): Promise<NotifyDeceasedPersonResponse> => {
  try {
    const notifyInfoData: NotifyDeceasedPersonRequest = {
      institutionSubdomain: notificationInfo.institutionSubdomain,
      deceasedPerson: {
        firstName: notificationInfo.deceasedPerson.firstName,
        middleName: notificationInfo.deceasedPerson.middleName,
        lastName: notificationInfo.deceasedPerson.lastName,
        ssn: cleanNumberString(notificationInfo.deceasedPerson.ssn),
        dateOfBirth: notificationInfo.deceasedPerson.dateOfBirth,
      },
      inheritor: {
        firstName: notificationInfo.inheritor.firstName,
        middleName: notificationInfo.inheritor.middleName,
        lastName: notificationInfo.inheritor.lastName,
        email: notificationInfo.inheritor.email,
        phoneNumber: cleanNumberString(notificationInfo.inheritor.phoneNumber),
        ssn: cleanNumberString(notificationInfo.inheritor.ssn),
        member: notificationInfo.inheritor.member,
      },
    };

    const accessToken = await getAccessToken();

    const data = await post<NotifyDeceasedPersonRequest, NotifyDeceasedPersonResponse>(
      `${CONSTANTS.VITE_APP_API_URL}/deceased-persons/notify`,
      notifyInfoData,
      {
        headers: accessToken
          ? {
              Authorization: accessToken,
            }
          : undefined,
      }
    );

    return data;
  } catch (error) {
    captureException(error);
    throw error;
  }
};

export const checkInheritanceStatus = async (
  inheritanceStatusRequest: SearchSubmittedInheritanceCaseRequest
): Promise<SearchSubmittedInheritanceCaseResponse> => {
  try {
    const accessToken = await getAccessToken();

    const data = await post<
      SearchSubmittedInheritanceCaseRequest,
      SearchSubmittedInheritanceCaseResponse
    >(
      `${CONSTANTS.VITE_APP_API_URL}/inheritance-cases/search-submitted`,
      inheritanceStatusRequest,
      {
        headers: accessToken
          ? {
              Authorization: accessToken,
            }
          : undefined,
      }
    );

    return data;
  } catch (error) {
    captureException(error);
    throw error;
  }
};
