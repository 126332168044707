import { ReactNode } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { Helmet } from "react-helmet-async";
import { useInstitutionData } from "@hooks/useInstitutionData.hook";
import { ROUTE_LOADER_IDS } from "@config/constants.config";
import { globalStyles } from "@shared/theme/styles";
import { theme } from "@shared/theme/theme";

interface MainLayoutProps {
  children: ReactNode;
  routeId: (typeof ROUTE_LOADER_IDS)[keyof typeof ROUTE_LOADER_IDS];
  title?: string;
}

export function MainLayout({ children, routeId, title }: Readonly<MainLayoutProps>) {
  const data = useInstitutionData(routeId);

  const currentTheme: typeof theme = {
    ...theme,
    radii: {
      ...globalStyles.radii,
      whiteLabel: {
        buttonBorderRadius:
          data?.config?.buttonBorderRadius ?? globalStyles?.radii.whiteLabel.buttonBorderRadius,
      },
    },
    colors: {
      ...globalStyles.colors,
      whiteLabel: {
        primary: data?.config?.primaryColor ?? globalStyles?.colors.whiteLabel.primary,
        secondary: data?.config?.secondaryColor ?? globalStyles?.colors.whiteLabel.secondary,
        tertiary: data?.config?.tertiaryColor ?? globalStyles?.colors.whiteLabel.tertiary,
        borderColor: data?.config?.borderColor ?? globalStyles?.colors.whiteLabel.borderColor,
        fontColor: data?.config?.fontColor ?? globalStyles?.colors.whiteLabel.fontColor,
        500: data?.config?.primaryColor ?? globalStyles?.colors.whiteLabel.primary,
      },
    },
    button: {
      whiteLabel: {
        bg: data?.config?.primaryColor ?? globalStyles?.colors.whiteLabel.primary,
        color: data?.config?.fontColor ?? globalStyles?.colors.whiteLabel.fontColor,
        borderColor: data?.config?.borderColor ?? globalStyles?.colors.whiteLabel.borderColor,
        borderRadius:
          data?.config?.buttonBorderRadius ?? globalStyles?.radii.whiteLabel.buttonBorderRadius,
        hover: {
          bg: data?.config?.secondaryColor ?? globalStyles?.colors.whiteLabel.secondary,
          color: data?.config?.fontColor ?? globalStyles?.colors.whiteLabel.fontColor,
        },
      },
    },
    styles: {
      ...theme.styles,
      global: () => ({
        ...theme.styles.global(),
        body: {
          ...theme.styles.global().body,
          backgroundColor: "#fff",
        },
      }),
    },
  };

  return (
    <ChakraProvider theme={currentTheme}>
      <Helmet>
        <link rel="icon" href={data?.config?.favicon ?? data?.config?.logo ?? "/ribbon-logo.svg"} />
        <title>{`Estate center services | ${title ?? data.name}`}</title>
        <meta property="og:title" content={`Estate center services | ${title ?? data.name}`} />
        <meta property="og:site_name" content={`https://${data.subdomain}.trustribbon.com`} />
        <meta property="og:url" content={`https://${data.subdomain}.trustribbon.com`} />
        {data.embedded && (
          <style type="text/css">{`
          html {
            overflow: hidden;
          }
        `}</style>
        )}
      </Helmet>
      {children}
    </ChakraProvider>
  );
}
