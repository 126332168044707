import { ComponentProps } from "react";
import { Button } from "@shared/components/Button/Button.component";
import { INSTITUTION_SUBDOMAINS, ROUTE_LOADER_IDS } from "@src/config/constants.config";
import { useInstitutionData } from "@src/hooks/useInstitutionData.hook";

type SecondaryButtonProps = ComponentProps<typeof Button> & {
  routeId: (typeof ROUTE_LOADER_IDS)[keyof typeof ROUTE_LOADER_IDS];
};

export function SecondaryButton({ onClick, routeId, children, ...rest }: SecondaryButtonProps) {
  const data = useInstitutionData(routeId);
  const isHapo = data.subdomain === INSTITUTION_SUBDOMAINS.HAPO;

  return (
    <Button
      {...rest}
      variant="whiteLabelOutline"
      onClick={onClick}
      border="1px solid"
      borderColor="whiteLabel.primary"
      padding="16px 32px"
      backgroundColor={isHapo ? "rgba(255, 205, 0, .2)" : "#fff"}
      color={isHapo ? "whiteLabel.fontColor" : undefined}
      _hover={{
        color: isHapo ? "whiteLabel.fontColor" : "white",
        bg: isHapo ? "rgba(255, 205, 0, .4)" : "whiteLabel.secondary",
        border: "1.9px solid",
        borderColor: "whiteLabel.secondary",
      }}
    >
      {children}
    </Button>
  );
}
