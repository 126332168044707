/* eslint-disable react-refresh/only-export-components */
import { lazy, Suspense } from "react";
import { RouteObject } from "react-router-dom";
import { ROUTES } from "@config/constants.config";
import { Loading } from "@shared/components/Loading/Loading.component";
import GetStartedPage from "@pages/GetStarted";
import TellUsAboutDeceasedPersonPage from "@pages/TellUsAboutDeceasedPerson";
import TellUsAboutYourselfPage from "@pages/TellUsAboutYourself";
import UploadDeathCertificatePage from "@pages/UploadDeathCertificate";
import InformationReviewPage from "@pages/InformationReview";
import SubmittedInfoPage from "@pages/SubmittedInfo";
import UploadGovIdPage from "@pages/UploadGovId";
import UploadWillTestPage from "@pages/UploadWillTest";
import CheckInheritanceStatusPage from "@pages/CheckInheritanceStatus";
import CheckSubmittedInfoPage from "@pages/CheckSubmittedInfo";
import { InheritanceVault } from "@src/templates/InheritanceVault/InheritanceVault.template";

//lazy loaded pages
const EstateServices = lazy(() => import("@pages/EstateServices"));
const Calendly = lazy(() => import("@pages/Calendly"));

export const EstateServicesLandingPageRoutes: RouteObject[] = [
  {
    path: ROUTES.ESTATE_SERVICES,
    element: (
      <Suspense fallback={<Loading />}>
        <EstateServices />
      </Suspense>
    ),
  },
  {
    path: ROUTES.CALENDLY,
    element: (
      <Suspense fallback={<Loading />}>
        <Calendly />
      </Suspense>
    ),
  },
  {
    path: ROUTES.GET_STARTED,
    element: (
      <Suspense fallback={<Loading />}>
        <GetStartedPage />
      </Suspense>
    ),
  },
  {
    path: ROUTES.CHECK_INHERITANCE_STATUS,
    element: (
      <Suspense fallback={<Loading />}>
        <CheckInheritanceStatusPage />
      </Suspense>
    ),
  },
  {
    path: ROUTES.DECEASED_PERSON_INFO,
    element: (
      <Suspense fallback={<Loading />}>
        <TellUsAboutDeceasedPersonPage />
      </Suspense>
    ),
  },
  {
    path: ROUTES.INHERITOR_INFO,
    element: (
      <Suspense fallback={<Loading />}>
        <TellUsAboutYourselfPage />
      </Suspense>
    ),
  },
  {
    path: ROUTES.UPLOAD_DEATH_CERTIFICATE,
    element: (
      <Suspense fallback={<Loading />}>
        <UploadDeathCertificatePage />
      </Suspense>
    ),
  },
  {
    path: ROUTES.UPLOAD_GOV_ID,
    element: (
      <Suspense fallback={<Loading />}>
        <UploadGovIdPage />
      </Suspense>
    ),
  },
  {
    path: ROUTES.UPLOAD_WILL_TEST,
    element: (
      <Suspense fallback={<Loading />}>
        <UploadWillTestPage />
      </Suspense>
    ),
  },
  {
    path: ROUTES.REVIEW_INFO,
    element: (
      <Suspense fallback={<Loading />}>
        <InformationReviewPage />
      </Suspense>
    ),
  },
  {
    path: ROUTES.SUBMITTED_INFO,
    element: (
      <Suspense fallback={<Loading />}>
        <SubmittedInfoPage />
      </Suspense>
    ),
  },
  {
    path: ROUTES.SUBMITTED_INFO_STATUS,
    element: (
      <Suspense fallback={<Loading />}>
        <CheckSubmittedInfoPage />
      </Suspense>
    ),
  },
  {
    path: ROUTES.INHERITANCE_VAULT,
    element: (
      <Suspense fallback={<Loading />}>
        <InheritanceVault />
      </Suspense>
    ),
  },
];
