import { BaseSyntheticEvent, useEffect, useState } from "react";
import { Divider, Flex, Heading, Text } from "@chakra-ui/react";
import { DocumentType } from "@trustribbon/ribbon-ec-types";
import { useIsMobile } from "@shared/hooks/useIsMobile.hook";
import { useNavigation } from "@shared/hooks/useNavigation.hook";
import { EnhancedFile } from "@shared/types";
import { InheritorReview } from "@shared/components/InformationReview/InheritorReview.component";
import { DeceasedReview } from "@shared/components/InformationReview/DeceasedReview.component";
import { UploadedReview } from "@shared/components/InformationReview/UploadedReview.component";
import { useDocumentStore } from "@shared/store/document.store";
import { ROUTES, ROUTE_LOADER_IDS } from "@config/constants.config";
import { useInstitutionData } from "@hooks/useInstitutionData.hook";
import { useNotifyInfoStore } from "@store/notifyInfo.store";
import { NotifyNavigationBlock } from "@components/Forms/Blocks/NotifyNavigation.block.component";
import { Page } from "@components/Layouts/Page/Page.component";

export function InformationReviewTemplate() {
  const [error, setError] = useState({ message: "" });
  const isMobile = useIsMobile();
  const institution = useInstitutionData(ROUTE_LOADER_IDS.ESTATE_SERVICES);
  const [isLoading, setIsLoading] = useState(false);
  const { inheritorInfo, deceasedInfo, createInheritanceCase } = useNotifyInfoStore();
  const { document } = useDocumentStore();
  const { navigate } = useNavigation();

  const isDisabled =
    !inheritorInfo ||
    !deceasedInfo ||
    !document?.["death-certificates"] ||
    !document?.["government-ids"];

  const documentEntries = Object.entries(document ?? {}) as [DocumentType, EnhancedFile | null][];

  useEffect(() => {
    if (!deceasedInfo || !inheritorInfo) {
      navigate(ROUTES.GET_STARTED);
    }
  }, [deceasedInfo, inheritorInfo, navigate]);

  const handleSubmitInfo = async (e: BaseSyntheticEvent) => {
    e.preventDefault();

    if (isDisabled) return null;

    setIsLoading(true);

    try {
      await createInheritanceCase(
        {
          inheritor: { ...inheritorInfo, middleName: inheritorInfo?.middleName ?? null },
          deceasedPerson: { ...deceasedInfo, middleName: deceasedInfo?.middleName ?? null },
          institutionSubdomain: institution?.subdomain,
        },
        documentEntries
      );

      navigate(ROUTES.SUBMITTED_INFO);
    } catch (error) {
      console.error("Error", error);
      setError({ message: "An error occurred while submitting your information." });
      setIsLoading(false);
    }
  };

  return (
    <Page
      title="Review your information"
      routeId={ROUTE_LOADER_IDS.ESTATE_SERVICES}
      pageMainProps={{ bg: "#fff" }}
    >
      <Flex
        as="form"
        id="review-information"
        gap={6}
        direction="column"
        onSubmit={handleSubmitInfo}
        px={isMobile ? 2 : 12}
      >
        <Flex direction="column">
          <Heading color="#171923" variant="h1" fontSize={30} fontWeight={600} lineHeight="36px">
            Information review
          </Heading>
        </Flex>
        <Divider color="#CBD5E0" />
        <Flex gap={6} direction="column">
          <Text fontSize="20px" fontWeight={600} lineHeight="28px">
            Deceased personal information
          </Text>
          <DeceasedReview isMobile={isMobile} deceasedInfo={deceasedInfo} />
        </Flex>
        <Divider color="#CBD5E0" />
        <Flex gap={6} direction="column">
          <Text fontSize="20px" fontWeight={600} lineHeight="28px">
            Your personal information
          </Text>
          <InheritorReview isMobile={isMobile} inheritorInfo={inheritorInfo} />
        </Flex>
        {/* <Divider color="#CBD5E0" />
        <Flex gap={6} direction="column">
          <Text fontSize="20px" fontWeight={600} lineHeight="28px">
            Add document
          </Text>
          <UploadedReview isDisabled={isDisabled} documentEntries={documentEntries} />
        </Flex> */}
        <Divider color="#CBD5E0" />
        <Flex gap={6} direction="column">
          <Text fontSize="20px" fontWeight={600} lineHeight="28px">
            Documents
          </Text>
          <UploadedReview
            institution={institution}
            isDisabled={isDisabled}
            documentEntries={documentEntries}
          />
          {error?.message && (
            <Text fontSize="16px" fontWeight={600} lineHeight="24px" color="red">
              {error.message || "An error occurred while submitting your information."}
            </Text>
          )}
        </Flex>
        <Flex gap={6} direction="column" mb={16} pt={2}>
          <Divider color="#CBD5E0" />
          <NotifyNavigationBlock
            isLoading={isLoading}
            isDisabled={isDisabled}
            onContinueLabel="Submit information"
            {...(isDisabled && { onClickBackRoute: ROUTES.UPLOAD_DEATH_CERTIFICATE })}
          />
        </Flex>
      </Flex>
    </Page>
  );
}
