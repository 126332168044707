import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { Sentry } from "@src/sentry";
import { App } from "./App";

if (process.env.NODE_ENV === "production" && import.meta.env.VITE_SENTRY_DSN_WEB) {
  Sentry.init();
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
