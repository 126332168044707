import {
  Box,
  Heading,
  VStack,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Spacer,
  HStack,
  Avatar,
  Flex,
} from "@chakra-ui/react";

export default function InheritanceVaultLinkedAccounts() {
  return (
    <VStack w={"full"} alignItems={"flex-start"} gap={2}>
      <Box w={"full"}>
        <Heading fontSize="20" fontWeight={600} color="#171923">
          Assets
        </Heading>

        <VStack w={"full"} pl={2} pt={2}>
          <Box w={"full"}>
            <Accordion allowToggle w={"full"}>
              <AccordionItem borderTop={"white"}>
                <AccordionButton px={0}>
                  <HStack spacing={2}>
                    <Avatar src={`https://static.trustribbon.com/wells-fargo_logo.png`} size="md" />
                    <VStack alignItems={"flex-start"} gap={0}>
                      <Text fontSize="sm" fontWeight={700}>
                        Wells Fargo
                      </Text>
                      <HStack>
                        <Text fontSize="sm" color="#718096">
                          Current Balance:
                        </Text>
                        <Text fontSize="sm" fontWeight={600} lineHeight="24px" color="#171923">
                          $2,100
                        </Text>
                      </HStack>
                    </VStack>
                  </HStack>
                  <Spacer />
                  <AccordionIcon fontSize={"32px"} />
                </AccordionButton>
                <AccordionPanel pb={2} px={0}>
                  <Flex gap={4} direction="column">
                    <VStack width="100%" gap={4}>
                      <HStack
                        gap={6}
                        align="flex-start"
                        direction="row"
                        width="100%"
                        flexWrap="wrap"
                      >
                        <VStack flexBasis="20%" flexGrow={1} alignItems="flex-start">
                          <Text
                            fontSize="16px"
                            lineHeight="24px"
                            color="#718096"
                            whiteSpace="nowrap"
                          >
                            Account Type:{" "}
                          </Text>
                          <Text fontSize="16px" fontWeight={600} lineHeight="24px" color="#171923">
                            Checking
                          </Text>
                        </VStack>
                      </HStack>
                    </VStack>
                    <VStack width="100%" gap={4}>
                      <HStack
                        gap={6}
                        align="flex-start"
                        direction="row"
                        width="100%"
                        flexWrap="wrap"
                      >
                        <VStack flexBasis="20%" flexGrow={1} alignItems="flex-start">
                          <Text
                            fontSize="16px"
                            lineHeight="24px"
                            color="#718096"
                            whiteSpace="nowrap"
                          >
                            Routing Number:
                          </Text>
                          <Text fontSize="16px" fontWeight={600} lineHeight="24px" color="#171923">
                            021000021
                          </Text>
                        </VStack>
                        <VStack flexBasis="20%" flexGrow={1} alignItems="flex-start">
                          <Text
                            fontSize="16px"
                            lineHeight="24px"
                            color="#718096"
                            whiteSpace="nowrap"
                          >
                            Account Number{" "}
                          </Text>
                          <Text fontSize="16px" fontWeight={600} lineHeight="24px" color="#171923">
                            345678901
                          </Text>
                        </VStack>
                      </HStack>
                    </VStack>
                  </Flex>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>
          <Box w={"full"}>
            <Accordion allowToggle w={"full"}>
              <AccordionItem borderTop={"white"}>
                <AccordionButton px={0}>
                  <HStack spacing={2}>
                    <Avatar src={`https://static.trustribbon.com/schwab_logo.png`} size="md" />
                    <VStack alignItems={"flex-start"} gap={0}>
                      <Text fontSize="sm" fontWeight={700}>
                        Charles Schwab
                      </Text>
                      <HStack>
                        <Text fontSize="sm" color="#718096">
                          Current Balance:
                        </Text>
                        <Text fontSize="sm" fontWeight={600} lineHeight="24px" color="#171923">
                          $48,290
                        </Text>
                      </HStack>
                    </VStack>
                  </HStack>
                  <Spacer />
                  <AccordionIcon fontSize={"32px"} />
                </AccordionButton>
                <AccordionPanel pb={2} px={0}>
                  <Flex gap={4} direction="column">
                    <VStack width="100%" gap={4}>
                      <HStack
                        gap={6}
                        align="flex-start"
                        direction="row"
                        width="100%"
                        flexWrap="wrap"
                      >
                        <VStack flexBasis="20%" flexGrow={1} alignItems="flex-start">
                          <Text
                            fontSize="16px"
                            lineHeight="24px"
                            color="#718096"
                            whiteSpace="nowrap"
                          >
                            Account Type:{" "}
                          </Text>
                          <Text fontSize="16px" fontWeight={600} lineHeight="24px" color="#171923">
                            Brokerage Account (Individual)
                          </Text>
                        </VStack>
                      </HStack>
                    </VStack>
                    <VStack width="100%" gap={4}>
                      <HStack
                        gap={6}
                        align="flex-start"
                        direction="row"
                        width="100%"
                        flexWrap="wrap"
                      >
                        <VStack flexBasis="20%" flexGrow={1} alignItems="flex-start">
                          <Text
                            fontSize="16px"
                            lineHeight="24px"
                            color="#718096"
                            whiteSpace="nowrap"
                          >
                            Account Number{" "}
                          </Text>
                          <Text fontSize="16px" fontWeight={600} lineHeight="24px" color="#171923">
                            234567890
                          </Text>
                        </VStack>
                      </HStack>
                    </VStack>
                  </Flex>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>
          <Box w={"full"}>
            <Accordion allowToggle w={"full"}>
              <AccordionItem borderTop={"white"}>
                <AccordionButton px={0}>
                  <HStack spacing={2}>
                    <Avatar src={`https://static.trustribbon.com/fidelity_logo.png`} size="md" />
                    <VStack alignItems={"flex-start"} gap={0}>
                      <Text fontSize="sm" fontWeight={700}>
                        Fidelity{" "}
                      </Text>
                      <HStack>
                        <Text fontSize="sm" color="#718096">
                          Current Balance:
                        </Text>
                        <Text fontSize="sm" fontWeight={600} lineHeight="24px" color="#171923">
                          $80,290
                        </Text>
                      </HStack>
                    </VStack>
                  </HStack>
                  <Spacer />
                  <AccordionIcon fontSize={"32px"} />
                </AccordionButton>
                <AccordionPanel pb={2} px={0}>
                  <Flex gap={4} direction="column">
                    <VStack width="100%" gap={4}>
                      <HStack
                        gap={6}
                        align="flex-start"
                        direction="row"
                        width="100%"
                        flexWrap="wrap"
                      >
                        <VStack flexBasis="20%" flexGrow={1} alignItems="flex-start">
                          <Text
                            fontSize="16px"
                            lineHeight="24px"
                            color="#718096"
                            whiteSpace="nowrap"
                          >
                            Account Type:{" "}
                          </Text>
                          <Text fontSize="16px" fontWeight={600} lineHeight="24px" color="#171923">
                            Retirement Account (Roth IRA)
                          </Text>
                        </VStack>
                      </HStack>
                    </VStack>
                    <VStack width="100%" gap={4}>
                      <HStack
                        gap={6}
                        align="flex-start"
                        direction="row"
                        width="100%"
                        flexWrap="wrap"
                      >
                        <VStack flexBasis="20%" flexGrow={1} alignItems="flex-start">
                          <Text
                            fontSize="16px"
                            lineHeight="24px"
                            color="#718096"
                            whiteSpace="nowrap"
                          >
                            Account Number{" "}
                          </Text>
                          <Text fontSize="16px" fontWeight={600} lineHeight="24px" color="#171923">
                            100928333
                          </Text>
                        </VStack>
                      </HStack>
                    </VStack>
                  </Flex>
                </AccordionPanel>{" "}
              </AccordionItem>
            </Accordion>
          </Box>
          <Box w={"full"}>
            <Accordion allowToggle w={"full"}>
              <AccordionItem borderTop={"white"}>
                <AccordionButton px={0}>
                  <HStack spacing={2}>
                    <Avatar
                      color="gray.500"
                      border={"1px"}
                      src={`https://static.trustribbon.com/marcus-by-goldman-sachs_logo.png`}
                      size="md"
                    />
                    <VStack alignItems={"flex-start"} gap={0}>
                      <Text fontSize="sm" fontWeight={700}>
                        Marcus
                      </Text>
                      <HStack>
                        <Text fontSize="sm" color="#718096">
                          Current Balance:
                        </Text>
                        <Text fontSize="sm" fontWeight={600} lineHeight="24px" color="#171923">
                          $12,090
                        </Text>
                      </HStack>
                    </VStack>
                  </HStack>
                  <Spacer />
                  <AccordionIcon fontSize={"32px"} />
                </AccordionButton>
                <AccordionPanel pb={2} px={0}>
                  <Flex gap={4} direction="column">
                    <VStack width="100%" gap={4}>
                      <HStack
                        gap={6}
                        align="flex-start"
                        direction="row"
                        width="100%"
                        flexWrap="wrap"
                      >
                        <VStack flexBasis="20%" flexGrow={1} alignItems="flex-start">
                          <Text
                            fontSize="16px"
                            lineHeight="24px"
                            color="#718096"
                            whiteSpace="nowrap"
                          >
                            Account Type:{" "}
                          </Text>
                          <Text fontSize="16px" fontWeight={600} lineHeight="24px" color="#171923">
                            Certificate Of Deposit
                          </Text>
                        </VStack>
                      </HStack>
                    </VStack>
                    <VStack width="100%" gap={4}>
                      <HStack
                        gap={6}
                        align="flex-start"
                        direction="row"
                        width="100%"
                        flexWrap="wrap"
                      >
                        <VStack flexBasis="20%" flexGrow={1} alignItems="flex-start">
                          <Text
                            fontSize="16px"
                            lineHeight="24px"
                            color="#718096"
                            whiteSpace="nowrap"
                          >
                            Routing Number:
                          </Text>
                          <Text fontSize="16px" fontWeight={600} lineHeight="24px" color="#171923">
                            266691965
                          </Text>
                        </VStack>
                        <VStack flexBasis="20%" flexGrow={1} alignItems="flex-start">
                          <Text
                            fontSize="16px"
                            lineHeight="24px"
                            color="#718096"
                            whiteSpace="nowrap"
                          >
                            Account Number{" "}
                          </Text>
                          <Text fontSize="16px" fontWeight={600} lineHeight="24px" color="#171923">
                            52550079
                          </Text>
                        </VStack>
                      </HStack>
                    </VStack>
                  </Flex>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>
        </VStack>
      </Box>

      <Box w={"full"} pt={4}>
        <Heading fontSize="20" fontWeight={600} color="#171923">
          Liabilities
        </Heading>
        <VStack w={"full"} pl={2} pt={2}>
          <Box w={"full"}>
            <Accordion allowToggle w={"full"}>
              <AccordionItem borderTop={"white"}>
                <AccordionButton px={0}>
                  <HStack spacing={2}>
                    <Avatar
                      src={`https://static.trustribbon.com/american-express_logo.png`}
                      size="md"
                    />
                    <VStack alignItems={"flex-start"} gap={0}>
                      <Text fontSize="sm" fontWeight={700}>
                        American Express
                      </Text>
                      <HStack>
                        <Text fontSize="sm" color="#718096">
                          Current Balance:
                        </Text>
                        <Text fontSize="sm" fontWeight={600} lineHeight="24px" color="#171923">
                          $5,590
                        </Text>
                      </HStack>
                    </VStack>
                  </HStack>
                  <Spacer />
                  <AccordionIcon fontSize={"32px"} />
                </AccordionButton>
                <AccordionPanel pb={2} px={0}>
                  <Flex gap={4} direction="column">
                    <VStack width="100%" gap={4}>
                      <HStack
                        gap={6}
                        align="flex-start"
                        direction="row"
                        width="100%"
                        flexWrap="wrap"
                      >
                        <VStack flexBasis="20%" flexGrow={1} alignItems="flex-start">
                          <Text
                            fontSize="16px"
                            lineHeight="24px"
                            color="#718096"
                            whiteSpace="nowrap"
                          >
                            Account Type:{" "}
                          </Text>
                          <Text fontSize="16px" fontWeight={600} lineHeight="24px" color="#171923">
                            Credit Card
                          </Text>
                        </VStack>
                      </HStack>
                    </VStack>
                    <VStack width="100%" gap={4}>
                      <HStack
                        gap={6}
                        align="flex-start"
                        direction="row"
                        width="100%"
                        flexWrap="wrap"
                      >
                        <VStack flexBasis="20%" flexGrow={1} alignItems="flex-start">
                          <Text
                            fontSize="16px"
                            lineHeight="24px"
                            color="#718096"
                            whiteSpace="nowrap"
                          >
                            Account Number{" "}
                          </Text>
                          <Text fontSize="16px" fontWeight={600} lineHeight="24px" color="#171923">
                            52550079
                          </Text>
                        </VStack>
                      </HStack>
                    </VStack>
                  </Flex>
                </AccordionPanel>{" "}
              </AccordionItem>
            </Accordion>
          </Box>
          <Box w={"full"}>
            <Accordion allowToggle w={"full"}>
              <AccordionItem borderTop={"white"}>
                <AccordionButton px={0}>
                  <HStack spacing={2}>
                    <Avatar
                      src={`https://static.trustribbon.com/bank-of-america_logo.png`}
                      size="md"
                      color="gray.500"
                      border={"1px"}
                    />
                    <VStack alignItems={"flex-start"} gap={0}>
                      <Text fontSize="sm" fontWeight={700}>
                        Bank of America
                      </Text>
                      <HStack>
                        <Text fontSize="sm" color="#718096">
                          Current Balance:
                        </Text>
                        <Text fontSize="sm" fontWeight={600} lineHeight="24px" color="#171923">
                          $10,300
                        </Text>
                      </HStack>
                    </VStack>
                  </HStack>
                  <Spacer />
                  <AccordionIcon fontSize={"32px"} />
                </AccordionButton>
                <AccordionPanel pb={2} px={0}>
                  <Flex gap={4} direction="column">
                    <VStack width="100%" gap={4}>
                      <HStack
                        gap={6}
                        align="flex-start"
                        direction="row"
                        width="100%"
                        flexWrap="wrap"
                      >
                        <VStack flexBasis="20%" flexGrow={1} alignItems="flex-start">
                          <Text
                            fontSize="16px"
                            lineHeight="24px"
                            color="#718096"
                            whiteSpace="nowrap"
                          >
                            Account Type:{" "}
                          </Text>
                          <Text fontSize="16px" fontWeight={600} lineHeight="24px" color="#171923">
                            Credit Card
                          </Text>
                        </VStack>
                      </HStack>
                    </VStack>
                    <VStack width="100%" gap={4}>
                      <HStack
                        gap={6}
                        align="flex-start"
                        direction="row"
                        width="100%"
                        flexWrap="wrap"
                      >
                        <VStack flexBasis="20%" flexGrow={1} alignItems="flex-start">
                          <Text
                            fontSize="16px"
                            lineHeight="24px"
                            color="#718096"
                            whiteSpace="nowrap"
                          >
                            Account Number{" "}
                          </Text>
                          <Text fontSize="16px" fontWeight={600} lineHeight="24px" color="#171923">
                            69076257
                          </Text>
                        </VStack>
                      </HStack>
                    </VStack>
                  </Flex>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>
        </VStack>
      </Box>
    </VStack>
  );
}
