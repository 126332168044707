export interface Message {
  message: string;
  payload: object;
}

/**
 * Post a message to the parent window.
 * @param message The message to post.
 * @param targetOrigin The target origin.
 * @returns void
 * @example
 * ```ts
 * postMessage({ message: "resize-view", payload: { width: 100, height: 100 } }, "https://www.institution-website.com");
 * ```
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
 */
export function postMessage(message: Message, targetOrigin: string) {
  return window.parent.postMessage(message, targetOrigin);
}
