import { Container, ContainerProps } from "@chakra-ui/react";
import { ReactNode } from "react";

interface SectionProps extends ContainerProps {
  children: ReactNode;
}

export function Section(props: SectionProps) {
  const { children, ...rest } = props;

  return (
    <Container as="section" maxW="full" {...rest}>
      {children}
    </Container>
  );
}
