import { create } from "zustand";
import { devtools, persist, createJSONStorage } from "zustand/middleware";
import {
  DocumentType,
  NotifyDeceasedPersonRequest,
  NotifyDeceasedPersonResponse,
} from "@trustribbon/ribbon-ec-types";
import { notifyDeceasedInfo } from "@services/notifyDeceasedInfo.service";
import { getUploadUrl, uploadDocument } from "@services/document.service";
import { EnhancedFile } from "@shared/types";
import { DeceasedPersonStore, InheritorStore } from "@shared/config/schema.validator";

interface NotifyInfoStore {
  loading: boolean;
  deceasedInfo: DeceasedPersonStore | null;
  inheritorInfo: InheritorStore | null;
  notifyResponse: NotifyDeceasedPersonResponse | null;
  setNotifyResponse: (notifyResponse: NotifyDeceasedPersonResponse) => void;
  setDeceasedInfo: (deceasedInfo: DeceasedPersonStore) => void;
  setInheritorInfo: (inheritorInfo: InheritorStore) => void;
  createInheritanceCase: (
    notifyInfo: NotifyDeceasedPersonRequest,
    documentEntries: [DocumentType, EnhancedFile | null][]
  ) => Promise<NotifyDeceasedPersonResponse>;
  clearPersistedStore: () => void;
}

const uploadDocumentsRecursively = async (
  documentEntries: [DocumentType, EnhancedFile | null][],
  notifiedInfo: NotifyDeceasedPersonResponse,
  institutionSubdomain: string
) => {
  if (documentEntries.length === 0) {
    return;
  }

  const [currentDocumentEntry, ...restOfEntries] = documentEntries || [];
  const [documentType, file] = currentDocumentEntry || [];

  if (file) {
    const uploadUrl = await getUploadUrl({
      filename: `${file.name}`,
      deceasedPersonId: notifiedInfo.deceasedPerson.id,
      inheritorId: notifiedInfo.inheritor.id,
      institutionSubdomain: institutionSubdomain,
      documentType,
    });

    await uploadDocument(uploadUrl, file);

    if (restOfEntries.length > 0) {
      await uploadDocumentsRecursively(restOfEntries, notifiedInfo, institutionSubdomain);
    }
  }
};

export const useNotifyInfoStore = create(
  devtools(
    persist<NotifyInfoStore>(
      set => ({
        loading: false,
        deceasedInfo: null,
        inheritorInfo: null,
        notifyResponse: null,
        setNotifyResponse: notifyResponse => set({ notifyResponse }),
        setInheritorInfo: inheritorInfo => set({ inheritorInfo }),
        setDeceasedInfo: deceasedInfo => set({ deceasedInfo }),
        createInheritanceCase: async (notifyInfo, documentEntries) => {
          set({ loading: true });

          const notifyResponse = await notifyDeceasedInfo(notifyInfo);
          await uploadDocumentsRecursively(
            documentEntries,
            notifyResponse,
            notifyInfo.institutionSubdomain
          );

          set({ loading: false, notifyResponse });

          return notifyResponse;
        },
        clearPersistedStore: () => {
          set({ loading: false, deceasedInfo: null, inheritorInfo: null, notifyResponse: null });
          useNotifyInfoStore.persist.clearStorage();
        },
      }),
      {
        name: "notifyInfo-storage",
        storage: createJSONStorage(() => sessionStorage),
      }
    ),
    {
      enabled: process.env.NODE_ENV === "development",
      anonymousActionType: "set notifyInfo",
      store: "notifyInfoStore",
      name: "Notify Info",
    }
  )
);
