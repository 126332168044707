import { ROUTE_LOADER_IDS } from "@config/constants.config";
import { Page } from "@components/Layouts/Page/Page.component";
import { Box, Divider, Flex, Heading, HStack, VStack, Text } from "@chakra-ui/react";
import { useIsMobile } from "@shared/hooks/useIsMobile.hook";
import { FiArrowLeft } from "react-icons/fi";
import { SecondaryButton } from "@src/components/Buttons/SecondaryButton/SecondaryButton";
import { MdLock } from "react-icons/md";
import { useNavigation } from "@shared/hooks/useNavigation.hook";
import { ROUTES } from "@config/constants.config";
import InheritanceVaultLinkedAccounts from "@templates/InheritanceVault/components/InheritanceVaultLinkedAccounts.components";
import { InheritanceVaultDocuments } from "@templates/InheritanceVault/components/InheritanceVaultDocuments.components";

export function InheritanceVault() {
  const routeId = ROUTE_LOADER_IDS.ESTATE_SERVICES;
  const isMobile = useIsMobile();
  const { navigate } = useNavigation();

  const handleCheckSubmittedInfoTemplate = () => {
    return navigate(ROUTES.SUBMITTED_INFO_STATUS);
  };

  return (
    <Page
      title="Inheritance Vault"
      routeId={ROUTE_LOADER_IDS.ESTATE_SERVICES}
      pageMainProps={{ bg: "#fff" }}
    >
      <Flex id="review-information" gap={5} direction="column">
        <Flex direction="column" py={2} px={isMobile ? 2 : 12}>
          <Box mb={6}>
            <SecondaryButton
              onClick={handleCheckSubmittedInfoTemplate}
              routeId={routeId}
              fontWeight={600}
              fontSize={14}
              variant="whiteLabel"
              size="md"
              borderRadius={6}
              leftIcon={<FiArrowLeft />}
            >
              Back
            </SecondaryButton>
          </Box>
          <HStack>
            <Heading fontSize="24" fontWeight={600} lineHeight="36px" color="#171923">
              Special message from your loved one
            </Heading>
          </HStack>
          <Divider color="#CBD5E0" />
          <Text fontSize="16px" fontWeight={400} lineHeight="24px" color="#4A5568" py={4}>
            Your loved one recorded a video to show how much you meant to them. We hope it brings
            you comfort.
          </Text>

          <VStack>
            <video
              src="https://static.trustribbon.com/assets/video/love_you.mp4"
              width="600px"
              height="450"
              controls
              muted
              playsInline
            />
          </VStack>
        </Flex>
        <Flex direction="column" pt={4} px={isMobile ? 2 : 12}>
          <HStack>
            <Heading fontSize="24" fontWeight={600} lineHeight="36px" color="#171923">
              Shared documents
            </Heading>
          </HStack>
          <Divider color="#CBD5E0" />
        </Flex>
        <Flex direction="column" px={isMobile ? 2 : 12}>
          <InheritanceVaultDocuments />
        </Flex>
        <Flex direction="column" pt={4} px={isMobile ? 2 : 12}>
          <HStack>
            <Heading fontSize="24" fontWeight={600} lineHeight="36px" color="#171923">
              Linked accounts
            </Heading>
          </HStack>
          <Divider color="#CBD5E0" />
        </Flex>
        <Flex direction="column" px={isMobile ? 2 : 12}>
          <InheritanceVaultLinkedAccounts />
          <HStack spacing={2} align="center" w={isMobile ? "full" : "auto"} pt={2}>
            <MdLock size="32px" color="#48BB78" />
            <Text fontSize="21px" color="#171923" fontWeight={500} lineHeight="56px">
              We use advanced encryption standards to secure your information.
            </Text>
          </HStack>
        </Flex>
      </Flex>
    </Page>
  );
}
