import { captureException } from "@sentry/react";
import { CONSTANTS } from "@src/config/constants.config";
import {
  RequestFundsWithdrawalRequest,
  RequestFundsWithdrawalResponse,
} from "@trustribbon/ribbon-ec-types";
import { getAccessToken } from "./authenticate.service";
import { post } from "./base.service";

export const postAccountAllocation = async (
  endpoint: string,
  body: RequestFundsWithdrawalRequest
) => {
  try {
    const accessToken = await getAccessToken();

    return await post<RequestFundsWithdrawalRequest, RequestFundsWithdrawalResponse>(
      `${CONSTANTS.VITE_APP_API_URL}/inheritance-cases/${endpoint}`,
      body,
      {
        headers: accessToken
          ? {
              Authorization: accessToken,
            }
          : undefined,
      }
    );
  } catch (error) {
    captureException(error);
    throw error;
  }
};
