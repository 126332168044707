import { useRouteLoaderData } from "react-router-dom";
import { GetInstitutionBySubdomainResponse } from "@trustribbon/ribbon-ec-types";
import { ROUTE_LOADER_IDS } from "@config/constants.config";

export function useInstitutionData<T = GetInstitutionBySubdomainResponse>(
  routeId: (typeof ROUTE_LOADER_IDS)[keyof typeof ROUTE_LOADER_IDS] = ROUTE_LOADER_IDS.ROOT_EC
) {
  const data = useRouteLoaderData(routeId) as T & { embedded: true };

  return data;
}
