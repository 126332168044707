import { postMessage } from "./postMessage.utils";

function getWidth() {
  if (typeof window === "undefined") return;

  return Math.max(window.document.body.scrollWidth, window.document.body.offsetWidth);
}

function getHeight(iframeContainerId: string) {
  if (typeof window === "undefined") return;

  return window.document.querySelector<HTMLElement>(iframeContainerId)?.offsetHeight ?? 0;
}

type Sizes = {
  width: number;
  height: number;
};

/**
 * Post message to resize view
 * @param targetOrigin
 * @param optional sizes
 * @returns void
 * @example
 * ```ts
 * postMessageResizeView("https://www.institution-website.com", { width: 100, height: 100 });
 * ```
 */
export function postMessageResizeView(targetOrigin: string, sizes?: Sizes) {
  if (typeof window === "undefined") return;

  return postMessage(
    {
      message: "resize-view",
      payload: {
        width: sizes ? sizes.width : getWidth(),
        height: sizes ? sizes.height : getHeight("#page-container"),
      },
    },
    targetOrigin
  );
}
