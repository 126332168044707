import { HStack, StackProps } from "@chakra-ui/react";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa6";
import { Button } from "@shared/components/Button/Button.component";
import { useNavigation } from "@shared/hooks/useNavigation.hook";
import { SecondaryButton } from "@src/components/Buttons/SecondaryButton/SecondaryButton";
import { ROUTE_LOADER_IDS } from "@src/config/constants.config";

type NotifyNavigationBlockProps = {
  isDisabled?: boolean;
  isLoading?: boolean;
  onContinue?: () => void;
  onGoBackLabel?: string;
  onContinueLabel?: string;
  onClickBackRoute?: string;
} & StackProps;

export function NotifyNavigationBlock({
  isDisabled,
  isLoading,
  onContinue,
  onGoBackLabel,
  onContinueLabel,
  onClickBackRoute,
  ...rest
}: Readonly<NotifyNavigationBlockProps>) {
  const { navigate, handleGoBack } = useNavigation();

  const buttonProps = {
    size: "lg",
    border: "1px solid",
    borderColor: "whiteLabel.primary",
    padding: "16px 32px",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "24px",
    w: "50%",
    variant: "whiteLabel",
    _hover: {
      bg: "whiteLabel.secondary",
      border: "1.9px solid",
      borderColor: "whiteLabel.secondary",
    },
  };

  return (
    <HStack spacing={6} align="flex-start" justify="flex-end" flexWrap="wrap" {...rest}>
      <SecondaryButton
        {...buttonProps}
        flexBasis="25%"
        onClick={() => (onClickBackRoute ? navigate(onClickBackRoute) : handleGoBack())}
        isDisabled={isLoading}
        gap={2}
        routeId={ROUTE_LOADER_IDS.ESTATE_SERVICES}
      >
        <FaArrowLeft size={16} /> {onGoBackLabel ?? "Back"}
      </SecondaryButton>
      <Button
        {...buttonProps}
        type={onContinue ? "button" : "submit"}
        gap={2}
        flexGrow={1}
        isDisabled={isDisabled}
        isLoading={isLoading}
        onClick={onContinue}
      >
        {onContinueLabel ?? "Continue"} <FaArrowRight size={16} />
      </Button>
    </HStack>
  );
}
