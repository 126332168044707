import { VStack } from "@chakra-ui/react";
import { Stepper } from "@shared/components/Stepper/Stepper.component";
import { ROUTE_LOADER_IDS } from "@config/constants.config";
import { notificationSteps } from "@config/notification-steps.config";
import { DeceasedPersonForm } from "@components/Forms/DeceasedPersonForm.component";
import { Page } from "@components/Layouts/Page/Page.component";

export function TellUsAboutDeceasedPersonTemplate() {
  return (
    <Page
      title="Tell us about the person who passed away"
      routeId={ROUTE_LOADER_IDS.ESTATE_SERVICES}
      pageMainProps={{ bg: "#fff" }}
    >
      <VStack gap={10} width="100%" alignItems="flex-start" px={12}>
        <Stepper steps={notificationSteps} />
        <DeceasedPersonForm />
      </VStack>
    </Page>
  );
}
