import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Flex, HStack, Heading, Divider } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ROUTES } from "@config/constants.config";
import { useNotifyInfoStore } from "@store/notifyInfo.store";
import { usPhoneMask } from "@shared/config/masks.config";
import { useNavigation } from "@shared/hooks/useNavigation.hook";
import { FormInput } from "@shared/components/FormComponents/FormInput.component";
import { useIsMobile } from "@shared/hooks/useIsMobile.hook";
import { InheritorStore, inheritorFormSchema } from "@shared/config/schema.validator";
import { PIIInputs } from "@shared/components/FormComponents/PIIInputs.component";
import { CustomSelect } from "@shared/components/FormComponents/CustomSelect.component";
import { YES_NO_SELECT_OPTIONS } from "@shared/config/constants.config";
import { NotifyNavigationBlock } from "./Blocks/NotifyNavigation.block.component";
import { GetInstitutionBySubdomainResponse } from "@trustribbon/ribbon-ec-types";

const defaultValues = {
  firstName: "",
  middleName: null,
  lastName: "",
  email: "",
  ssn: "",
  phoneNumber: "",
  member: undefined,
};

const formInputProps = {
  labelProps: { backgroundColor: "#fff" },
  size: "lg",
  formControlProps: {
    flexGrow: 1,
    width: "auto",
  },
};

interface InheritorFormProps {
  institution: GetInstitutionBySubdomainResponse;
}

export function InheritorForm({ institution }: InheritorFormProps) {
  const isMobile = useIsMobile();
  const { inheritorInfo, setInheritorInfo } = useNotifyInfoStore(state => ({
    inheritorInfo: state.inheritorInfo,
    setInheritorInfo: state.setInheritorInfo,
  }));
  const { navigate } = useNavigation();
  const formMethods = useForm<InheritorStore>({
    defaultValues: inheritorInfo ?? defaultValues,
    resolver: yupResolver(inheritorFormSchema),
  });

  const { handleSubmit, formState } = formMethods;
  const { errors } = formState;
  const hasErrors =
    !!errors.firstName ||
    !!errors.lastName ||
    !!errors.email ||
    !!errors.ssn ||
    !!errors.phoneNumber ||
    !!errors.member;

  const onSubmit: SubmitHandler<InheritorStore> = data => {
    setInheritorInfo(data);

    return navigate(ROUTES.UPLOAD_DEATH_CERTIFICATE);
  };

  return (
    <FormProvider {...formMethods}>
      <Flex
        as="form"
        id="inheritor-form"
        onSubmit={handleSubmit(onSubmit)}
        direction="column"
        width="100%"
      >
        <Flex gap={6} direction="column" mb={16}>
          <Heading color="#171923" variant="h1" fontSize={24} fontWeight={600} lineHeight="32px">
            Tell us about yourself
          </Heading>
          <PIIInputs formInputProps={formInputProps} />
          <HStack gap={6} align="flex-start" direction="row" flexWrap="wrap">
            <FormInput
              data-testid="email-input"
              label="Email"
              name="email"
              registerOptions={{ required: true }}
              {...formInputProps}
            />
            <FormInput
              data-testid="phone-number-input"
              label="Phone"
              name="phoneNumber"
              variant="whiteLabel"
              mask={usPhoneMask}
              registerOptions={{ required: true }}
              maxLength={17}
              {...formInputProps}
            />
          </HStack>
          <Heading variant="h1" color="#171923" fontSize={20} fontWeight={600} lineHeight="28px">
            Do you have an account with us already?
          </Heading>
          <HStack
            pr={3}
            align="flex-start"
            direction="row"
            width={isMobile ? "100%" : "50%"}
            flexWrap="wrap"
          >
            <CustomSelect
              data-testid="member-input"
              isEditing={true}
              name="member"
              placeholder={institution && institution.type === "bank" ? "Customer" : "Member"}
              registerOptions={{ required: true }}
              closeMenuOnSelect
              options={YES_NO_SELECT_OPTIONS}
              formControlProps={{
                ...formInputProps.formControlProps,
              }}
              inputGroupProps={{
                sx: {
                  "& .react-select": {
                    width: "100%",
                    height: "48px",
                    "& > div": {
                      height: "48px",
                    },
                  },
                },
                boxShadow: "inset 0 0 3px #007dba",
                borderRadius: "6px",
              }}
              chakraStyles={{
                option: styles => ({
                  ...styles,
                  "&:hover": {
                    backgroundColor: "#F8F8F8",
                  },
                }),
                dropdownIndicator: provided => ({
                  ...provided,
                  px: 4,
                }),
              }}
            />
          </HStack>
        </Flex>
        <Flex gap={6} direction="column" mb={16}>
          <Divider color="#CBD5E0" />
          <NotifyNavigationBlock isDisabled={hasErrors} />
        </Flex>
      </Flex>
    </FormProvider>
  );
}
