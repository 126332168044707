import {
  Flex,
  HStack,
  Heading,
  Text,
  VStack,
  Container,
  Stack,
  Image,
  Accordion,
  AccordionButton,
  AccordionPanel,
  AccordionItem,
  AccordionIcon,
  Spacer,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import { Button } from "@shared/components/Button/Button.component";
import { useIsMobile } from "@shared/hooks/useIsMobile.hook";
import { useNavigation } from "@shared/hooks/useNavigation.hook";
import { ROUTES, ROUTE_LOADER_IDS } from "@config/constants.config";
import { Page } from "@components/Layouts/Page/Page.component";
import { SecondaryButton } from "@src/components/Buttons/SecondaryButton/SecondaryButton";
import { Section } from "@src/pages/EstateServices/components/Section.estateServices";
import { MdLock } from "react-icons/md";

export function GetStartedTemplate() {
  const isMobile = useIsMobile();
  const { navigate } = useNavigation();
  const routeId = ROUTE_LOADER_IDS.ESTATE_SERVICES;

  const handleGetStarted = () => {
    return navigate(ROUTES.DECEASED_PERSON_INFO);
  };

  const handleCheckInheritanceStatus = () => {
    return navigate(ROUTES.CHECK_INHERITANCE_STATUS);
  };

  return (
    <Page title="Get Started" routeId={routeId} pageMainProps={{ bg: "#fff" }}>
      <Section id="trust-services-section" backgroundColor="#ffffffe6" px={0} pt={8}>
        <Container
          pb={{ base: "10", md: 20 }}
          px={{ base: "0", md: "12" }}
          maxW={{ base: "full", md: "5xl" }}
        >
          <Flex
            alignItems="flex-start"
            justifyContent="center"
            flexWrap={{ base: "wrap", md: "nowrap" }}
          >
            <HStack spacing={4}>
              <Image
                maxW={"xs"}
                borderRadius={10}
                src={"https://static.trustribbon.com/assets/losing-a-loved-one-16x9.jpg"}
                alt="decorative image asset"
              />
              <Stack spacing={"4"}>
                <Text fontSize="16pt" fontWeight={200}>
                  When a loved one passes away, handling their financial matters can be
                  overwhelming. Our inheritance center is here to help you understand what to expect
                  and guide you through the process.
                </Text>
              </Stack>
            </HStack>
          </Flex>
        </Container>
      </Section>
      <Flex gap={8} direction="column" px={isMobile ? 2 : 12}>
        <VStack gap={4} align="flex-start" w={"full"}>
          <Heading fontSize="20" fontWeight={600} color="#171923">
            Understanding the inheritance process
          </Heading>
          <Accordion allowToggle w={"full"} allowMultiple>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Text fontSize="18" fontWeight={500}>
                    Initial steps you should take
                  </Text>
                  <Spacer />
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <UnorderedList>
                  <ListItem>
                    <strong>Obtain the Death Certificate:</strong> This is the first document you'll
                    need.
                  </ListItem>
                  <ListItem>
                    <strong>Notify us:</strong> Inform us as soon as possible to begin the process.
                  </ListItem>
                  <ListItem>
                    <strong>Important Documents: </strong>Gather the will, trust documents, and any
                    identification.
                  </ListItem>
                </UnorderedList>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Text fontSize="18" fontWeight={500}>
                    Documents you may need
                  </Text>
                  <Spacer />
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <UnorderedList>
                  <ListItem>
                    <strong>Death Certificate:</strong> Certified copies are often required.
                  </ListItem>
                  <ListItem>
                    <strong>Proof of Identity:</strong> A valid ID or passport.
                  </ListItem>
                  <ListItem>
                    <strong>Beneficiary Designation Forms: </strong> If you are named as a
                    beneficiary.
                  </ListItem>
                  <ListItem>
                    <strong>Trust Agreement Documents: </strong> If a trust is involved.
                  </ListItem>
                  <ListItem>
                    <strong>Letters of Administration: </strong> For executors or administrators.
                  </ListItem>
                </UnorderedList>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Text fontSize="18" fontWeight={500}>
                    Potential scenarios you may encounter during the process
                  </Text>
                  <Spacer />
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <UnorderedList>
                  <ListItem>
                    <strong>Beneficiary listed on account:</strong> You'll need the death
                    certificate and your ID.
                  </ListItem>
                  <ListItem>
                    <strong>No beneficiary listed on account:</strong> The executor will need to
                    provide a court order.
                  </ListItem>
                  <ListItem>
                    <strong>Trust account: </strong> Trustees must present trust documents and their
                    ID.
                  </ListItem>
                  <ListItem>
                    <strong>Joint account holder</strong> The surviving holder needs the death
                    certificate.
                  </ListItem>
                </UnorderedList>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Text fontSize="18" fontWeight={500}>
                    What to expect from the transfer process
                  </Text>
                  <Spacer />
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <UnorderedList>
                  <ListItem>Account balances will be transferred.</ListItem>
                  <ListItem>Account records will be updated.</ListItem>
                  <ListItem>Funds can be issued as checks or direct transfers.</ListItem>
                </UnorderedList>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Text fontSize="18" fontWeight={500}>
                    Be prepared for possible tax implications for certain types of accounts.
                  </Text>
                  <Spacer />
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Stack spacing={2}>
                  <Text>Inheriting money can have tax implications. Be prepared to:</Text>
                  <UnorderedList>
                    <ListItem>File the necessary tax forms.</ListItem>
                    <ListItem>Consult with a tax advisor to understand your obligations.</ListItem>
                  </UnorderedList>
                </Stack>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Text fontSize="18" fontWeight={500}>
                    Handling disputes
                  </Text>
                  <Spacer />
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Stack spacing={2}>
                  <Text>If there are contested claims:</Text>
                  <UnorderedList>
                    <ListItem>Accounts may be temporarily frozen.</ListItem>
                    <ListItem>Legal support may be required to resolve disputes.</ListItem>
                    <ListItem>We can guide you through the necessary steps.</ListItem>
                  </UnorderedList>
                </Stack>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </VStack>
        <VStack gap={4} align="flex-start">
          <Stack spacing={0}>
            <Heading fontSize="20" fontWeight={600} color="#171923">
              Notify us of a passing
            </Heading>
            <Text fontSize="16px" fontWeight={400} lineHeight="24px" color="#4A5568">
              Notifying us should take just a few minutes.
            </Text>
          </Stack>

          <HStack width="100%" flexWrap={isMobile ? "wrap" : "nowrap"}>
            <Button
              variant="whiteLabel"
              onClick={handleGetStarted}
              border="1px solid"
              borderColor="whiteLabel.borderColor"
              fontSize="16px"
              fontWeight={600}
              lineHeight="24px"
              w="full"
              size={"lg"}
            >
              Start
            </Button>
            <SecondaryButton
              routeId={routeId}
              onClick={handleCheckInheritanceStatus}
              w="full"
              fontSize="16px"
              size={"lg"}
              fontWeight={600}
            >
              Check inheritance status
            </SecondaryButton>
          </HStack>
          <HStack spacing={2} align="center" w={isMobile ? "full" : "auto"}>
            <MdLock size="32px" color="#48BB78" />
            <Text fontSize="21px" color="#171923" fontWeight={500} lineHeight="56px">
              We use advanced encryption standards to secure your information.
            </Text>
          </HStack>
        </VStack>
      </Flex>
    </Page>
  );
}
