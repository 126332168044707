import { create } from "zustand";
import { devtools, persist, createJSONStorage } from "zustand/middleware";
import { SearchSubmittedInheritanceCaseResponse } from "@trustribbon/ribbon-ec-types";

interface CheckStatusInfoStore {
  loading: boolean;
  checkStatusResponse: SearchSubmittedInheritanceCaseResponse | null;
  setCheckStatusResponse: (checkStatusResponse: SearchSubmittedInheritanceCaseResponse) => void;
  clearPersistedStore: () => void;
}

export const useCheckStatusInfoStore = create(
  devtools(
    persist<CheckStatusInfoStore>(
      set => ({
        loading: false,
        checkStatusResponse: null,
        setCheckStatusResponse: checkStatusResponse => set({ checkStatusResponse }),
        clearPersistedStore: () => {
          set({ loading: false, checkStatusResponse: null });
          useCheckStatusInfoStore.persist.clearStorage();
        },
      }),
      {
        name: "checkStatusInfo-storage",
        storage: createJSONStorage(() => sessionStorage),
      }
    ),
    {
      enabled: process.env.NODE_ENV === "development",
      anonymousActionType: "set checkStatusInfo",
      store: "checkStatusInfoStore",
      name: "Check Status Info",
    }
  )
);
