import { createBrowserRouter, Outlet, redirect, RouterProvider } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { EstateServicesLandingPageRoutes } from "@routes/client.routes";
import { CONSTANTS, ROUTE_LOADER_IDS, ROUTES } from "@config/constants.config";
import { fetchInstitutionConfig } from "@services/institutionConfig.service";
import { Loading } from "@shared/components/Loading/Loading.component";
import { MainLayout } from "@components/Layouts/Main/Main.component";
import { NotFoundPage } from "@pages/NotFound";

/**
 * Sanitize the URL to add protocol if needed and remove www from the host
 * @param rawUrl
 */
const cleanURL = (rawUrl?: string | null) => {
  if (!rawUrl) return undefined;

  const rawUrlWithProtocol = rawUrl.startsWith("https://") ? rawUrl : `https://${rawUrl}`;
  const urlHost = new URL(rawUrlWithProtocol).host;

  return urlHost
    ? urlHost.replace(/^(https?:\/\/)?(www\d*\.)+/i, "$1").replace("https://", "")
    : undefined;
};

const router = createBrowserRouter([
  {
    id: ROUTE_LOADER_IDS.ESTATE_SERVICES,
    path: "/",
    element: (
      <MainLayout routeId={ROUTE_LOADER_IDS.ESTATE_SERVICES}>
        <Outlet />
      </MainLayout>
    ),
    errorElement: <NotFoundPage />,
    children: EstateServicesLandingPageRoutes,
    loader: async ({ request }) => {
      const currentURL = new URL(request.url);
      const pathname = currentURL.pathname;
      const [institution, demo] = currentURL.host.split(".");

      if (!institution) {
        return redirect(CONSTANTS.VITE_APP_DEFAULT_FALLBACK_URL);
      }

      const isEmbed = demo === "demo";

      if (pathname === ROUTES.ROOT) {
        return redirect(ROUTES.ESTATE_SERVICES);
      }

      if (isEmbed) {
        const sanitizedDocumentReferrer = cleanURL(document.referrer);

        try {
          const institutionData = await fetchInstitutionConfig(institution);

          // This is to prevent the iframe from being embedded in other websites
          // institutionData.config.websiteDomain MUST match with the institution's website,
          // ex.: https://www.myinstutition.co.uk -> websiteDomain must be https://www.myinstutition.co.uk
          const allowedHosts = [
            // These first 2 are only meant to allow us within trustribbon.com
            "trustribbon.com",
            cleanURL(currentURL.host)?.replace(".demo", ""),
            cleanURL(institutionData.config?.websiteDomain),
          ];

          if (sanitizedDocumentReferrer && !allowedHosts.includes(sanitizedDocumentReferrer)) {
            return redirect(CONSTANTS.VITE_APP_DEFAULT_FALLBACK_URL);
          }

          return {
            ...institutionData,
            embedded: isEmbed,
          };
        } catch {
          return redirect(CONSTANTS.VITE_APP_DEFAULT_FALLBACK_URL);
        }
      }

      try {
        const institutionData = await fetchInstitutionConfig(institution);

        return {
          ...institutionData,
          embedded: isEmbed,
        };
      } catch {
        return redirect(CONSTANTS.VITE_APP_DEFAULT_FALLBACK_URL);
      }
    },
  },
]);

export function App() {
  return (
    <HelmetProvider>
      <RouterProvider router={router} fallbackElement={<Loading />} />
    </HelmetProvider>
  );
}
