import { Stack, Box, Image, SystemStyleObject } from "@chakra-ui/react";
import { INSTITUTION_SUBDOMAINS, ROUTE_LOADER_IDS } from "@config/constants.config";
import { useInstitutionData } from "@hooks/useInstitutionData.hook";

interface FooterProps {
  routeId: (typeof ROUTE_LOADER_IDS)[keyof typeof ROUTE_LOADER_IDS];
}

export function Footer({ routeId }: Readonly<FooterProps>) {
  const institution = useInstitutionData(routeId);
  const css: SystemStyleObject = {
    [INSTITUTION_SUBDOMAINS.ACUTX]: { bg: "whiteLabel.secondary" },
    [INSTITUTION_SUBDOMAINS.BANCOFCAL]: { bg: "whiteLabel.secondary" },
    [INSTITUTION_SUBDOMAINS.CALCOASTCU]: { bg: "#ffffff" },
    [INSTITUTION_SUBDOMAINS.HAPO]: { bg: "#000" },
    [INSTITUTION_SUBDOMAINS.MSUFCU]: { bg: "#ffffff" },
    [INSTITUTION_SUBDOMAINS.SRPFCU]: { bg: "whiteLabel.primary" },
    [INSTITUTION_SUBDOMAINS.VALLEYSTRONG]: { bg: "#12301e" },
    default: { bg: "#f4f8fa" },
  };

  if (institution.embedded) return null;

  return (
    <Box
      as="section"
      marginTop="auto"
      sx={css[institution.subdomain] || css.default}
      height={"full"}
      flex={"1 1 auto"}
      display={"flex"}
      justifyContent={"center"}
    >
      <Stack py={16} justify="center" textAlign="center" spacing={10}>
        <Box>
          <Image
            src={institution.config?.logo}
            alt={institution.name}
            width={{ base: 150, md: 200 }}
            display="block"
            margin="auto"
          />
        </Box>
      </Stack>
    </Box>
  );
}
